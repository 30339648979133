import React from "react";

function Unit(props) {
    return <path d={props.paths[props.view]} name={props.name} style={props.style} onClick={(e) => {
        e.stopPropagation();
        e.currentTarget.focus();
        props.setPopupPosition({ x: e.pageX, y: e.pageY, e: e })
        props.setHide(false);
        props.setShowPopup(true);
        props.setPopupData(props.unit);
    }} />
}

export default Unit;

import React, { useContext, useEffect } from "react";
import DataContext from '../context/dataContext';

export default function ZohoSalesIQ() {
    const dataContext = useContext(DataContext);
    const { signinData } = dataContext;
    let SD;
    if(signinData){
        if (typeof signinData === 'string')
            SD = JSON.parse(signinData);
        else 
            SD = signinData;
    }

    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = {
        widgetcode: '74bd17bb9797a19a5f667178b6d58ba71ed832b43d1b28343d04f712dd0e31c8',
        values: {},
        ready: function () {
            if (signinData) {
                console.warn("window.$zoho.salesiq >> ", window.$zoho.salesiq);
                window.$zoho.salesiq.visitor.name((SD.firstname && SD.lastname) && `${SD.firstname} ${SD.lastname}` || SD.name || `${SD.localizedFirstName} ${SD.localizedLastName}`);
                window.$zoho.salesiq.visitor.email(SD.email || '');
            }
        },
    };

    const d = document;
    let s;
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com/widget';
    let t;
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    return (
        <React.Fragment>
            <div id='zsiqwidget'></div>
        </React.Fragment>
    );
}
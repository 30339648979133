import React from "react";
import ImageCard from './ImageCard'

const TwoImage = ({ left_image, right_image, left_title, right_title, left_url, right_url, left_target, right_target }) => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-6'>
          <a href={left_url} target={left_target} style={{textDecoration: 'none'}}>
            <div className='ti-card'>
              <img src={process.env.REACT_APP_API_URL + left_image.url} alt='' />
            </div>
            <h3 style={{
              textAlign: 'center',
              color: '#FFF',
              marginBottom: 60,
            }}>{left_title}</h3>
          </a>
        </div>
        <div className='col-lg-6'>
          <a href={right_url} target={right_target} style={{textDecoration: 'none'}}>
            <div className='ti-card'>
              <img src={process.env.REACT_APP_API_URL + right_image.url} alt='' />
            </div>
            <h3 style={{
              textAlign: 'center',
              color: '#FFF',
              marginBottom: 60,
            }}>{right_title}</h3>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TwoImage;

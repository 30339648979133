import React from "react";
import * as Scroll from "react-scroll";
import { useTranslation } from "react-i18next";

const FixedGetInTouchButton = () => {
  const { t } = useTranslation();

  let scroll = Scroll.animateScroll;

  const handleClick = () => {
    scroll.scrollToBottom();
  };

  return (
    <div className='fixed-button-wrapper' id='fixed-get-in-touch-button'>
      <button
        type='button'
        className='btn btn-light btn-block'
        onClick={handleClick}
      >
        {t("GET_IN_TOUCH")}
      </button>
    </div>
  );
};

export default FixedGetInTouchButton;

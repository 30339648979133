import gsap from 'gsap';
import { scroller } from 'react-scroll';
import $ from 'jquery';

export const handleOpenSearchAnimation = () => {
  const srctl = gsap.timeline();
  srctl.to('#search-icon', 0.3, {
    display: 'none',
  });
  srctl.to('#search-close-icon', 0.3, {
    display: 'block',
  });
  srctl.to('#search', 0.3, {
    opacity: 1,
    display: 'flex',
  });
  srctl.to('#search .form-group', 0.8, {
    opacity: 1,
    y: 0,
    ease: 'Power4.easeInOut',
    stagger: 0.07,
  });
  srctl.to('#search-btn', 0.8, {
    opacity: 1,
    y: 0,
    ease: 'Power4.easeInOut',
    stagger: 0.07,
  });
  $('body').addClass('overflow-hidden');
  $('body').removeClass('overflow-auto');
};
export const handleCloseSearchAnimation = () => {
  const srctl = gsap.timeline();
  srctl.to('#search-close-icon', 0.3, {
    display: 'none',
    stagger: 0.3,
  });
  srctl.to('#search-icon', 0.3, {
    display: 'block',
  });

  srctl.to('#search-btn', 0.8, {
    opacity: 0,
    y: 50,
    ease: 'Power4.easeInOut',
  });
  srctl.to('#search .form-group', 0.8, {
    opacity: 0,
    y: 50,
    ease: 'Power4.easeInOut',
    stagger: 0.07,
  });
  srctl.to('#search', 0.3, {
    opacity: 0,
    display: 'none',
    stagger: 0.07,
  });
  $('body').addClass('overflow-auto');
  $('body').removeClass('overflow-hidden');
  
};
export const handleMenuAnimation = (open) => {
  if (open === false) {
    const tl = gsap.timeline();
    tl.to('#mobile-menu', 0.3, {
      opacity: 1,
      display: 'block',
    });
    tl.to('#mobile-menu li', 0.4, {
      opacity: 1,
      x: 0,
      stagger: {
        amount: 0.05,
      },
      delay: 0.05,
    });
  } else {
    const tl = gsap.timeline();
    tl.to('#mobile-menu li', 0.4, {
      opacity: 0,
      x: -20,
      stagger: 0.05,
      delay: 0.05,
    });
    tl.to('#mobile-menu', 0.3, { opacity: 0, display: 'none' });
  }
};

export const fixedNavigationOpen = () => {
  /*
  body.addClass("fixed-nav-mobile-active");*/
  gsap.to('.fixed-navigation-mobile-handler ', 0.2, {
    opacity: 0,
    display: 'none',
  });
  gsap.to('.fixed-navigation', 0.4, {
    opacity: 1,
    display: 'flex',
  });
  gsap.to('.fixed-navigation-item', 2, {
    opacity: 1,
    y: '0',
    stagger: 0.1,
    delay: 0.4,
    ease: 'power4.out',
  });
};

// FIXED NAV CLOSE ANIMATION
export const fixedNavigationClose = () => {
  /*
  body.removeClass("fixed-nav-mobile-active");*/
  gsap.to('.fixed-navigation-mobile-handler ', 0.2, {
    opacity: 1,
    display: 'flex',
  });
  gsap.to('.fixed-navigation-item', 2, {
    opacity: 0,
    y: '100',
    stagger: -0.1,
    ease: 'power4.out',
  });
  gsap.to('.fixed-navigation', 0.4, {
    opacity: 0,
    display: 'none',
    delay: 0.15,
  });
};

export const fixedGetInTouchAnimate = (inView) => {
  if (inView) {
    gsap.to('#fixed-get-in-touch-button', 0.2, {
      y: 100,
      opacity: 0,
    });
  } else {
    gsap.to('#fixed-get-in-touch-button', 0.2, {
      y: 0,
      opacity: 1,
    });
  }
};

export const fixedActiveNavigationPartDesktop = (section, entry) => {
  if (entry) {
    $(`#${section}`).addClass('active');
  } else {
    $(`#${section}`).removeClass('active');
  }
};
export const handleScroll = (inView) => {
  if (window.innerWidth > 992) {
    const srctl = gsap.timeline();

    if (inView === true) {
      srctl.to('#get-in-touch-button', {
        display: 'none',
        opacity: 0,
      });
    }
    if (inView === false) {
      srctl.to('#get-in-touch-button', {
        display: 'block',
        opacity: 1,
      });
    }
  }
  if (window.scrollY > 300) {
    const srctl = gsap.timeline();
    srctl.to('#header', {
      display: 'none',
      y: -50,
    });
  }
  if (window.scrollY < 600) {
    const srctl = gsap.timeline();
    srctl.to('#header', {
      display: 'flex',
      y: 0,
    });
  }
};
export const scrollToSection = (name) => {
  scroller.scrollTo(name, {
    duration: 1000,
    smooth: 'easeInOutQuart',
    offset: 50,
  });
};

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { handleMenuAnimation } from "../animations/animation";
import DataContext from '../context/dataContext';

const MobileMenu = ({ handleOpen }) => {
  const dataContext = useContext(DataContext);
  const { staticPages } = dataContext;

  const handleClick = () => {
    handleOpen();
    handleMenuAnimation(true);
  };

  let mainMenu = [];
  if (staticPages) {
    staticPages.map((item) => {
      if (item.show_in_menu === true) {
        mainMenu.push(item);
      }
      return mainMenu; // I added return
    });
  }
  return (
    <nav id='mobile-menu'>
      <ul>
        {mainMenu.length > 0 && (
          <>
            {mainMenu.map((item) => (
              <li key={item.id}>
                <NavLink onClick={()=>{handleClick()}} to={`/${item.slug}`}>{item.name}</NavLink>
              </li>
            ))}
          </>
        )}
        <li>
          <NavLink onClick={()=>{handleClick()}} to={`/overview`}>Overview</NavLink>
        </li>
        <li>
          <NavLink onClick={()=>{handleClick()}} to={`/amenities`}>Amenities</NavLink>
        </li>
        <li>
          <NavLink onClick={()=>{handleClick()}} to={`/gallery`}>Gallery</NavLink>
        </li>
        <li>
          <NavLink onClick={()=>{handleClick()}} to={`/interactive-map`}>Interactive Map</NavLink>
        </li>
        <li>
          <NavLink onClick={()=>{handleClick()}} to={`/lease-now`}>Lease Now</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenu;

import React, { Fragment, useState, useContext, useEffect } from 'react';
import DataContext from '../context/dataContext';
import Footer from '../components/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import PromoLanding from '../components/PromoLanding';
import Promo from '../components/Promo';
import DesktopSearch from '../components/DesktopSearch';
import RegisterForm from '../components/RegisterForm';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import $ from 'jquery';
import CustomFixedNavigation from '../components/CustomFixedNavigation';
import PromoOverview from '../components/PromoOverview';
import PromoInteractiveMap from '../components/PromoInteractiveMap';
const InteractiveMap = () => {
  const dataContext = useContext(DataContext);
  const { getCompanies, companies, lang } = dataContext;
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, [lang]);
  const [hide, setHide] = useState('');
  // Set key for React Fullpage
  let anchors = [];
  companies.map((company) => {
    anchors.push(company.slug);
    return anchors;
  });
  anchors.push('footerr');

  return (
    <Fragment>
      <ReactFullpage
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        responsiveWidth={1100}
        className='promo-slider'
        navigation={true}
        navigationPosition='right'
        lockAnchors={true}
        controlArrows={true}
        // anchors={anchors}
        scrollingSpeed={700}
        onLeave={(origin) => {
          if (origin.anchor === 'footerr') {
            setHide('hide');
          } else {
            setHide('');
          }
        }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className='promo-slider'>
                {/* <div className='section'>
                  <PromoLanding fullpageApi={fullpageApi} />
                </div> */}

                {companies.map((company, index) => (
                  <div className='section' key={index} style={{backgroundColor: '#1d263d'}}>
                    <PromoInteractiveMap fullpageApi={fullpageApi} />
                    <CustomFixedNavigation
                      // price={project && project.price}
                      // moneyType={money}
                      navigationData={[{id: 1, name: 'OVERVIEW', to: 'overview'}, { id: 4, name: 'AMENITIES', to: 'amenities' }, { id: 5, name: 'GALLERY', to: 'gallery' }, {id: 2, name: 'INTERACTIVE MAP', to: 'interactive-map', active: true}, {id: 3, name: 'LEASE NOW', to: 'lease-now'}]}
                      backgroundColor={'#1d263d'}
                    />
                  </div>
                ))}

                <div className='section' id='section-footer'>
                  <RegisterForm />
                  <Footer />
                </div>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <FixedGetInTouchButton />
      {/* <DesktopSearch hide={hide} /> */}
    </Fragment>
  );
};

export default InteractiveMap;

import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ImageMasonry from "react-image-masonry";
import {isMobile} from 'react-device-detect';

const PromoGallery = ({ fullpageApi }) => {

  const [state, setState] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const photos = [
    'assets/img/gallery/1.jpg',
    'assets/img/gallery/3.jpg',
    'assets/img/gallery/4.jpg',
    'assets/img/gallery/5.jpg',
    'assets/img/gallery/6.jpg',
    'assets/img/gallery/7.jpg',
    'assets/img/gallery/8.jpg',
    'assets/img/gallery/9.jpg',
    'assets/img/gallery/10.jpg',
    'assets/img/gallery/11.jpg',
  ]
  const swiperimages =
    photos && photos.map((e) => e);
  const { photoIndex, isOpen } = state;
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section className='vr-promo'>
        <div onClick={()=>{
          setState({ ...state, isOpen: true });
        }}>
          <ImageMasonry
            imageUrls={photos}
            numCols={isMobile ? 1 : 3}
            containerWidth={window.innerWidth}
            containerHeight={window.innerHeight}
          />
        </div>
        <div>
          {isOpen && (
            <Lightbox
              mainSrc={swiperimages[photoIndex]}
              nextSrc={swiperimages[(photoIndex + 1) % swiperimages.length]}
              prevSrc={
                swiperimages[
                (photoIndex + swiperimages.length - 1) % swiperimages.length
                ]
              }
              onCloseRequest={() => setState({ ...state, isOpen: false })}
              onMovePrevRequest={() =>
                setState({
                  ...state,
                  photoIndex:
                    (photoIndex + swiperimages.length - 1) % swiperimages.length,
                })
              }
              onMoveNextRequest={() =>
                setState({
                  ...state,
                  photoIndex: (photoIndex + 1) % swiperimages.length,
                })
              }
            />
          )}
        </div>
        {/* <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/1.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/3.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/4.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center p-0 mt-0'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/5.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/6.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/7.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/8.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/gallery/9.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
          </div>
        </div> */}
        {/* <div className='promo-slide-down'>
          {t('VIEW_ALL_PROPERTIES')}
          <button
            type='button'
            className='promo-slide-down-button'
            onClick={handleClick}
          >
            <svg
              className='icon '
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              viewBox='0 0 24 24'
              width='20'
            >
              <path d='M0 0h24v24H0V0z' fill='none' />
              <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
            </svg>
          </button>
        </div>
        <div className='text-center'>
          <div className="container-fluid">
          <p>
            Donec a imperdiet odio, sit amet mollis erat. Quisque laoreet est id mi rhoncus, ac mattis massa feugiat. Integer et sem dolor. Donec tempus mauris lacus. Praesent nunc sapien, ullamcorper quis velit et, pretium faucibus arcu. Nulla facilisi. Nulla vel magna et nisl hendrerit dignissim. Pellentesque in enim ipsum. Vestibulum suscipit nunc arcu.
          </p>
          <p>
            Nam id sapien dolor. Etiam tristique lobortis purus sit amet pharetra. Aenean imperdiet lectus nisl, ac ullamcorper enim iaculis nec. Cras a ex ipsum. Duis semper vel turpis ut fermentum. Nullam efficitur felis eget mi scelerisque, non vulputate erat suscipit. Proin placerat lobortis leo sit amet porta. Pellentesque eleifend condimentum magna, at laoreet nisl placerat eget. Morbi vestibulum enim ullamcorper consectetur efficitur. Nulla facilisi.
          </p>
          </div>
          <h6 className='promo-sub-title'> WELCOME TO</h6>
          <h2 className='promo-title'>
            AL FARIDAH BUILDING
          </h2>
          <hl style={{ width: 400 }} />
          <h4 className=''>AL RAHA BEACH</h4>

          <p className='promo-text'>
          {t('PROMO_1')}
          <br className='d-none d-lg-block' /> {t('PROMO_2')}
        </p>
          <NavLink
            className='btn btn-outline-light promo-action-button mt-5'
            to={'al-raha-beach'}
          >
            {'DISCOVER'}
          </NavLink> 
        </div>
        {/* <video
        data-keepplaying
        autoPlay
        muted
        loop
        playsInline
        preload='yes'
        className='promo-video'
        poster='/assets/img/promo/al-faridah-block-c2.jpg'
      >  <source
            src="//stream.ayanaholding.com/390973231220726219099013.mp4"
            type='video/mp4'
          /> 
      </video> */}
      </section>
    </InView>
  );
};

export default PromoGallery;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
// import '../TourviewApp.css'

import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialLinkedin,
} from 'reactjs-social-login'

import { createButton } from "react-social-login-buttons";
import { Form, Button, Spinner, Col, Row } from 'react-bootstrap';
import { init } from '@emailjs/browser';
import emailjs from '@emailjs/browser';
import md5 from 'md5';
import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import DataContext from '../../context/dataContext';

init("Es-aRHgfPr2P34aoY");

const SocialButton = (props) => {
    const config = {
        text: props.text,
        icon: props.icon,
        iconFormat: name => `fa-brands fa-${props.icon}`,
        style: { background: "#FFF", color: '#000', fontSize: 16, paddingLeft: 40, marginTop: isMobile ? 0 : 30, marginBottom: isMobile ? 15 : 30, boxShadow: 'none', borderColor: '#DEDEDE', border: 1, borderStyle: 'solid' },
        activeStyle: { background: "rgb(239,240,238)", color: '#000' },
    };

    const SocialLoginButton = createButton(config);

    return <SocialLoginButton />;
}

const SocialLogin = ({ closeModal, setType }) => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState();
    const [formItems, setFormItems] = useState({ firstname: '', lastname: '', email: '' });
    const [loading, setLoading] = useState(false);
    const googleRef = useRef();
    const facebookRef = useRef();
    const [cookies, setCookie] = useCookies(['signin-data', 'signin-provider']);

    const dataContext = useContext(DataContext);
    const { setSigninData } = dataContext;
    //   getMoneyType(selectedMoney.value);
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

    useEffect(() => {
        if (localStorage.getItem('signin-data')) {
            const sd = JSON.parse(localStorage.getItem('signin-data'));
            setSigninData(sd);
            setFormItems({ firstname: sd?.firstname, lastname: sd?.lastname, email: sd?.email })
        }
    }, [])

    const onLoginStart = useCallback(() => {
        console.warn('login start')
    }, [])

    const onLogoutFailure = useCallback(() => {
        alert('logout fail')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])

    const onResolve = useCallback((result) => {
        console.warn("provider, data >> ", result.provider, result.data);
        localStorage.setItem('signin-provider', result.provider);
        localStorage.setItem('signin-data', JSON.stringify(result.data));
        localStorage.setItem('show-sign-in', false);
        setSigninData(JSON.stringify(result.data));
        setCookie('signin-provider', result.provider, { domain: 'alfaridah.ae', expires: moment().add(30, 'd').toDate() });
        setCookie('signin-data', JSON.stringify(result.data), { domain: 'alfaridah.ae', expires: moment().add(30, 'd').toDate() });
        closeModal();
    }, [])

    const onTextChange = (type, text) => {
        console.warn("type, text >> ", type, text);
        let sd = {}

        if (localStorage.getItem('signin-data')) {
            sd = JSON.parse(localStorage.getItem('signin-data'));
        }
        sd[type] = text.target.value;
        formItems[type] = text.target.value;
        console.warn("formItems[type] >>>> ", formItems[type], text.target.value);
        setFormItems(formItems);
        localStorage.setItem('signin-data', JSON.stringify(sd));

        console.warn("formItems >> ", formItems);
    }

    const validateEmail = (email) => {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return re.test(email);
    };

    const onSubmit = () => {
        if (!localStorage.getItem('signin-data')) {
            alert('Please fill your informations');
            return;
        }

        const sd = JSON.parse(localStorage.getItem('signin-data'));

        if (!sd.email || !sd.firstname || !sd.lastname) {
            alert('Please fill your informations');
            return;
        }

        if (!validateEmail(sd.email)) {
            alert('Please enter valid email');
            return;
        }
        setCookie('signin-data', JSON.stringify(sd), { domain: 'alfaridah.ae', expires: moment().add(30, 'd').toDate() });
        setSigninData(JSON.stringify(sd));
        setLoading(true);
        const ac = [1, 1, 1, 1, 1, 1].map(() => Math.floor(Math.random() * 10));

        localStorage.setItem('ac', md5(ac.join('')));

        var templateParams = {
            to_name: `${sd.firstname} ${sd.lastname}`,
            to_email: sd.email,
            activation_code: ac.join('')
        };

        emailjs.send('service_2ksdtlf', 'template_fht3f0o', templateParams)
            .then(function (response) {
                setType('activation');
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
    }

    return (
        <>
            {isMobile ? (
                <div className={`App ${provider && profile ? 'hide' : ''}`} style={{ width: window.innerWidth * 90 / 100, height: window.innerHeight * 70 / 100, }}>
                    <h4 className='title'>Xplor Available Units</h4>
                    <Row>
                        <Col md={5} sm={5}>
                            <div>
                                <Row>
                                    <Col md={6} sm={6}>
                                        <Form.Group className="mb-3" controlId="formBasicFullname">
                                            <Form.Control style={{ height: 50 }} type="text" placeholder="Firstname" onChange={(text) => { onTextChange('firstname', text) }} defaultValue={formItems['firstname']} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <Form.Group className="mb-3" controlId="formBasicFullname">
                                            <Form.Control style={{ height: 50 }} type="text" placeholder="Lastname" onChange={(text) => { onTextChange('lastname', text) }} defaultValue={formItems['lastname']} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control style={{ height: 50 }} type="email" placeholder="Email" onChange={(text) => { onTextChange('email', text) }} defaultValue={formItems['email']} />
                                </Form.Group>
                                <Button variant="primary" style={{ height: 50 }} type="button" className='w-100' onClick={onSubmit} disabled={loading}>
                                    {!loading ? (
                                        'Send Activation Code'
                                    ) : (
                                        <Spinner animation="border" />
                                    )}
                                </Button>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div style={{
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                position: isInstagram ? 'block' : 'absolute',
                                padding: isInstagram ? '10px 0' : 0
                            }}>or</div>
                        </Col>
                        <Col md={5}>
                            <LoginSocialFacebook
                                ref={facebookRef}
                                appId={'302937665262747'}
                                onLoginStart={onLoginStart}
                                onLogoutSuccess={onLogoutSuccess}
                                onResolve={onResolve}
                                onReject={(err) => {
                                    console.log(err)
                                }}
                            >
                                <SocialButton text="Sign in with Facebook" icon="facebook" />
                            </LoginSocialFacebook>

                            <LoginSocialGoogle
                                ref={googleRef}
                                client_id={'174196961154-1ap6gcoo1uh8hp868afjuh0kg6lkajb0.apps.googleusercontent.com'}
                                onLogoutFailure={onLogoutFailure}
                                onLoginStart={onLoginStart}
                                onLogoutSuccess={onLogoutSuccess}
                                onResolve={onResolve}
                                onReject={(err) => {
                                    console.log(err)
                                }}
                            >
                                <SocialButton text="Sign in with Google" icon="google" />
                            </LoginSocialGoogle>
                            {/* <LoginSocialLinkedin
                                ref={linkedinRef}
                                client_id={'7706hq2lshp7s3'}
                                client_secret={'euEXYmlKcc8HIWYj'}
                                redirect_uri={'https://xplor.alfaridah.ae'}
                                onLoginStart={onLoginStart}
                                onLogoutSuccess={onLogoutSuccess}
                                onResolve={onResolve}
                                onReject={(err) => {
                                    console.log(err)
                                }}
                            >
                                <SocialButton text="Sign in with Linkedin" icon="linkedin" />
                            </LoginSocialLinkedin> */}
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className={`App ${provider && profile ? 'hide' : ''}`} style={{ width: 350, maxWidth: window.innerWidth * 80 / 100 }}>
                    <h4 className='title'>Xplor Available Units</h4>
                    <hr />
                    <Row>
                        <Col md={6} sm={6}>
                            <Form.Group className="mb-3" controlId="formBasicFullname">
                                <Form.Control style={{ height: 50 }} type="text" placeholder="Firstname" onChange={(text) => { onTextChange('firstname', text) }} defaultValue={formItems['firstname']} />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={6}>
                            <Form.Group className="mb-3" controlId="formBasicFullname">
                                <Form.Control style={{ height: 50 }} type="text" placeholder="Lastname" onChange={(text) => { onTextChange('lastname', text) }} defaultValue={formItems['lastname']} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Email" onChange={(text) => { onTextChange('email', text) }} defaultValue={formItems['email']} />
                    </Form.Group>
                    <Button variant="primary" type="button" className='w-100' onClick={onSubmit} disabled={loading}>
                        {!loading ? (
                            'Send Activation Code'
                        ) : (
                            <Spinner animation="border" />
                        )}
                    </Button>
                    <hr />
                    or
                    <LoginSocialFacebook
                        ref={facebookRef}
                        appId={'302937665262747'}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={onResolve}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <SocialButton text="Sign in with Facebook" icon="facebook" />
                    </LoginSocialFacebook>

                    <LoginSocialGoogle
                        ref={googleRef}
                        client_id={'174196961154-1ap6gcoo1uh8hp868afjuh0kg6lkajb0.apps.googleusercontent.com'}
                        onLogoutFailure={onLogoutFailure}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={onResolve}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <SocialButton text="Sign in with Google" icon="google" />
                    </LoginSocialGoogle>
                    {/* <LoginSocialLinkedin
                        ref={linkedinRef}
                        client_id={'7706hq2lshp7s3'}
                        client_secret={'euEXYmlKcc8HIWYj'}
                        redirect_uri={'https://xplor.alfaridah.ae'}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={onResolve}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <SocialButton text="Sign in with Linkedin" icon="linkedin" />
                    </LoginSocialLinkedin> */}
                </div>
            )}
        </>
    )
}

export default SocialLogin
import React, { Fragment, useEffect, useContext } from 'react';
import DataContext from '../context/dataContext';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import _ from 'lodash';

import { useParams } from 'react-router-dom';
import PromoVRTourDetails from '../components/PromoVRTourDetails';
import VRTourFixedNavigation from '../components/VRTourFixedNavigation';
import FixedBookViewingButton from '../components/FixedBookViewingButton';

gsap.registerPlugin(TextPlugin);

const VRTourDetails = () => {
  const { t } = useTranslation();

  const dataContext = useContext(DataContext);
  const { getStaticPages, staticPages } = dataContext;
  useEffect(() => {
    getStaticPages();
    // eslint-disable-next-line
  }, []);
  let staticPage,
    sectionsIndex,
    slicer = [];
  const { block, unit } = useParams();
  // console.warn("staticPages >> ", staticPages, page);

  if (staticPages) {
    // staticPage = staticPages.find((x) => x.slug === page);
    //  console.warn("staticPage >> ", staticPage);

    if (staticPage) {
      //     console.warn("staticPage.sections >> ", staticPage.sections);
      const sections = _.filter(staticPage.sections, {
        __component: 'components.section-name',
      });
      //    console.warn("sections >> ", sections);
      sectionsIndex = _.map(sections, (s) => {
        return _.findIndex(staticPage.sections, s);
      });
      sectionsIndex.push(staticPage.sections.length);

      if (staticPage.sections.length > 2) {
        for (let index = 0; index <= sectionsIndex.length; index++) {
          slicer.push(_.take(sectionsIndex, 2));
          sectionsIndex = _.drop(sectionsIndex);
        }
      } else {
        slicer.push(_.take(sectionsIndex, 2));
      }
      //   console.warn("slicer >> ", slicer);
    }
  }

  const fixedNavigaitonMobileHandler = (entry, name) => {
    if (entry === true) {
      gsap.to('.fixed-navigation-mobile-handler', {
        duration: 0.3,
        text: `${t($(name)[0].innerHTML)}`,
      });
    }

    // console.log($(name)[0].innerHTML);
  };

  window.addEventListener("message", messageHandler, false);
  function messageHandler(event) {
    const { action, key, value } = event.data;
    if (action === 'returnData') {
      console.warn("key, value >> ", key, value);
    }
  }
  return (
    <Fragment>
      <PromoVRTourDetails
        detailClass='detail-promo'
        // data={staticPage}
        block={block}
        unit={unit}
      />
      <main
        className='main-content'
        style={{ backgroundColor: staticPage && staticPage.color }}
      >
        <VRTourFixedNavigation
          block={block}
          unit={unit}
          navigationData={staticPage}
          backgroundColor={staticPage && staticPage.color}
        />
      </main>
      {/* <RegisterForm /> */}
      {/* <Footer /> */}
      <FixedBookViewingButton block={block} unit={unit} />
    </Fragment>
  );
};

export default VRTourDetails;

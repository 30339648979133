import React from 'react';
import { InView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { handleScroll } from '../animations/animation';
import { useTranslation } from 'react-i18next';
const Promo = ({
  video,
  src,
  detailClass,
  fullpageApi,
  logo,
  hasLogo,
  title,
  hasPrice,
  price,
  sub_title,
  path,
  slug,
  hasButton,
  buttonTitle,
}) => {
  const handleClick = () => {
    fullpageApi
      ? fullpageApi.moveSectionDown()
      : scroller.scrollTo('myScrollToElement', {
          duration: 1000,
          delay: 50,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
  };
  const { t } = useTranslation();
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section
        className={`promo ${detailClass ? detailClass : null}`}
        style={{
          backgroundImage: `url(${
            src ? process.env.REACT_APP_API_URL + src : ''
          })`,
        }}
      >
        <div className='promo-slide-down'>
          {hasPrice && (
            <div style={{textAlign: 'center'}}>
              <span>{t('RENTAL PRICE')}</span>
              <h4>{'AED ' + (parseFloat(price)).toLocaleString()}{' '}</h4>
            </div>
          )}
          <button
            type='button'
            className='promo-slide-down-button'
            onClick={handleClick}
          >
            <svg
              className='icon '
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              viewBox='0 0 24 24'
              width='20'
            >
              <path d='M0 0h24v24H0V0z' fill='none' />
              <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
            </svg>
          </button>
        </div>
        <div className='promo-content'>
          <h6 className='promo-sub-title'>
            {sub_title && sub_title.toUpperCase()}
          </h6>
          {/* {hasLogo && (
            <h2 className='promo-title'><img src={process.env.REACT_APP_API_URL + logo.url} alt=""/></h2>
          )} */}
          <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
          <h3 className='promo-titlex' style={{opacity: 1, fontWeight: 'bold', marginTop: 20}}>
            {title?.toUpperCase()}
          </h3>
          {hasButton === true && (
            <NavLink
              className='btn btn-outline-light promo-action-button'
              state={slug}
              to={{ pathname: path, state: slug }}
            >
              {buttonTitle.toUpperCase()}
            </NavLink>
          )}
        </div>
        {video && (
          <video
            data-keepplaying
            autoPlay
            muted
            loop
            playsInline
            preload='yes'
            className='promo-video'
            poster='/assets/img/promo/1.jpg'
          >
            <source
              src={process.env.REACT_APP_API_URL + video}
              type='video/mp4'
            />
          </video>
        )}
      </section>
    </InView>
  );
};

export default Promo;

import React from "react";

const HeroContent = ({ sub_title, title, description }) => {
  return (
    <div className='hero-content'>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <div className='hero-content-title'>
              <h6 dangerouslySetInnerHTML={{ __html: sub_title }} />
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: description }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContent;

import React, { Fragment, useEffect, useContext } from 'react';

import Footer from '../components/Footer';
import DataContext from '../context/dataContext';
import ReactFullpage from '@fullpage/react-fullpage';
import RegisterForm from '../components/RegisterForm';
import PromoProjects from '../components/PromoProjects';
import NotFound from '../components/NotFound';
const Projects = ({ location }) => {
  const dataContext = useContext(DataContext);
  const { getProjects, projects, filteredState } = dataContext;

  useEffect(() => {
    getProjects(location, filteredState);
    // eslint-disable-next-line
  }, [filteredState]);
  if (projects === []) {
    return <NotFound />;
  }
  console.log(projects);
  return (
    <Fragment>
      <ReactFullpage
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        navigation={true}
        navigationPosition='right'
        lockAnchors={true}
        verticalCentered={false}
        scrollingSpeed={700}
        resetSliders={true}
        afterRender={() => {}}

        render={({ state, fullpageApi }) => {
          return (
            <div className='promo-slider'>
              {projects && projects.length > 0 ? (
                projects.map((projectItem) => (
                  <div className='section' key={projectItem.id}>
                    <PromoProjects
                      src={projectItem.cover.url}
                      fullpageApi={fullpageApi}
                      title={projectItem.name}
                      company={projectItem.company}
                      slug={projectItem.slug}
                      video={projectItem.video && projectItem.video.url}
                      price={projectItem.price}
                    />
                  </div>
                ))
              ) : (
                <NotFound />
              )}
              <div className='section fp-auto-height' id='section-footer'>
                <RegisterForm />
                <Footer />
              </div>
            </div>
          );
        }}
      />

      {/* <FixedGetInTouchButton /> */}
    </Fragment>
  );
};

export default Projects;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupModal } from "react-calendly";
import { useCookies } from "react-cookie";

const FixedBookViewingButton = ({block, unit}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const signinData = JSON.parse(localStorage.getItem('signin-data'));
  const [cookies] = useCookies(['signin-data', 'signin-provider']);
  const handleClick = () => {
    setIsOpen(true)
  };

  return (
    <div className='fixed-button-wrapper' id='fixed-get-in-touch-button'>
      <button
        type='button'
        className='btn btn-light btn-block'
        onClick={handleClick}
      >
        {t("BOOK A VIEWING")}
      </button>
      <PopupModal
        url="https://calendly.com/alfaridah/unit-viewing"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        prefill={{
          name: signinData ? ((signinData.firstname && signinData.lastname) && `${signinData.firstname} ${signinData.lastname}` || signinData.name || `${signinData.localizedFirstName} ${signinData.localizedLastName}`) : '',
          email: signinData ? (signinData.email || '') : '',
          customAnswers: {
            Block: block,
            Unit: unit
          },
          Block: block,
          Unit: unit,
          block: block,
          unit: unit
        }}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default FixedBookViewingButton;

import Unit from "./Unit";
import React, { useEffect, useRef, useState } from "react";
import HoverPopup from './HoverPopup';
import SocialLogin from '../Utils/SocialLogin';
import Modal from 'react-modal';
import Activation from '../Utils/Activation';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';

function Blocks(props) {
  const [showLogin, setShowLogin] = useState(false);
  const [showVR, setShowVR] = useState(false);
  const [cookies] = useCookies(['signin-provider']);
  const [type, setType] = useState('social');
  const [bi, setBi] = useState(0)
  const [popupData, setPopupData] = useState()
  const [showPopup, setShowPopup] = useState(false);
  const [hide, setHide] = useState(false);
  const [popupPosition, setPopupPosition] = useState({});
  const [isRotateActive, setRotateActive] = useState(false);
  const BlockImage = useRef()
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
  console.warn("isInstagram >> ", isInstagram);
  useEffect(() => {
    setBi(props.blockIndex);
    // props.setVideo(props.blocks[props.blockIndex].video);
  }, [props])

  useEffect(() => {
    const sl = localStorage.getItem('show-sign-in');
    if (sl === 'true') setShowLogin(true);
  }, [])

  useEffect(() => {
    const zsiq_floatmain = document.getElementsByClassName('zsiq_floatmain')[0];

    if (zsiq_floatmain) {
      console.warn("zsiq_floatmain >> ", zsiq_floatmain);
      zsiq_floatmain.style.marginBottom = showVR ? '60px' : 0;
    }
  }, [showVR])


  const onRotatePress = (nb) => {
    console.warn("isRotateActive >> ", isRotateActive);
    if (!isRotateActive) {
      setRotateActive(true);
      const bIndex = nb === 'plus' ? props.blockIndex + 1 : props.blockIndex - 1
      props.setPlayVideo(true);
      props.VideoPlayer.current.src = `//stream.ayanaholding.com/${props.blocks[props.blockIndex].video[nb === 'plus' ? 'forward' : 'backward']}.mp4`;
      props.VideoPlayer.current.play();
      props.VideoPlayer.current.onplaying = function () {
        BlockImage.current.href.baseVal = `assets/blocks/${props.blocks[bIndex]?.view}.jpg`
        props.getUnits(bIndex);
      };
      props.VideoPlayer.current.onended = function () {
        setBi(bIndex);
        setTimeout(() => {
          props.setBlockIndex(bIndex);
          props.setPlayVideo(false);
          setRotateActive(false);
        }, 300);
      };
    }
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: '#000000a6'
    },
  };
  const ModalContent = () => {
    return (
      type === 'social' ? (
        <SocialLogin closeModal={closeModal} setType={setType} />
      ) : (
        <Activation setShowLogin={setShowLogin} />
      )
    )
  }
  function closeModal() {
    setShowLogin(false);
  }
  function closeVRModal() {
    setShowVR(false);
  }
  return (
    <div onClick={() => {
      setHide(true)
    }}>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x={0}
        y={0}
        height="100%"
        width="100%"
        viewBox="0 0 3840 2160"
        xmlSpace="preserve"
        preserveAspectRatio="xMidYMid slice"
        style={{
          position: 'absolute',
          display: 'block',
          overflow: 'hidden',
          left: 0,
          top: 0,
          margin: 0,
          padding: 0,
          maxWidth: 'none',
          maxHeight: 'none',
          border: 'none',
          lineHeight: 1,
          backgroundColor: 'transparent',
          backfaceVisibility: 'hidden',
          userSelect: 'none',
          visibility: 'visible'
        }}
      >
        <image
          style={{
            overflow: "hidden",
            enableBackground: "new",
          }}
          width={3840}
          height={2160}
          ref={BlockImage}
          href={`assets/blocks/${props.blocks[bi]?.view}.jpg`}
        />
        <g>
          {props.units.map((unit, index) => {
            return <Unit paths={unit.paths} name={unit.name} unit={unit} view={props.blocks[bi]?.view} style={{ fill: unit.status === 'available' ? '#02bc02' : 'red', display: props.isFiltered || unit.status !== 'available' ? 'block' : 'none' }} key={index} setPopupPosition={setPopupPosition} setShowPopup={setShowPopup} setHide={setHide} setPopupData={setPopupData} showLogin={setShowLogin} />
          })}
        </g>
      </svg>
      {props.blocks.length > props.blockIndex + 1 && (
        <div>
          <span className="nav right-arrow" style={!isInstagram ? { right: 80, fontSize: 18, padding: 5, marginTop: 8, opacity: 0 } : { right: 80, fontSize: 18, padding: 5, marginTop: 8, opacity: 0, right: 10, top: '45%' }}>ROTATE</span>
          <div className="badge nav nav_right" style={!isInstagram ? { borderRadius: 5 } : {borderRadius: 5, right: 10, top: '45%'}} onClick={() => {
            onRotatePress('plus');
          }}>
            <i className="icon-reply"></i>
          </div>
        </div>
      )}
      {props.blockIndex !== 0 && (
        <div>
          <span className="nav  left-arrow" style={!isInstagram ? { left: 80, fontSize: 18, padding: 5, marginTop: 8, opacity: 0 } : {left: 80, fontSize: 18, padding: 5, marginTop: 8, opacity: 0, left: 10, top: '45%'}}>ROTATE</span>
          <div className="badge nav nav_left" style={!isInstagram ? { borderRadius: 5 } : {borderRadius: 5, left: 10, top: '45%'}} onClick={() => {
            onRotatePress('minus');
          }}>
            <i className="icon-reply"></i>
          </div>
        </div>
      )}
      <HoverPopup showPopup={showPopup} hide={hide} popupPosition={popupPosition} popupData={popupData} cookies={cookies} setShowLogin={setShowLogin} setShowVR={setShowVR} />
      <Modal
        isOpen={showVR}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '0%',
            left: '0%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '0%',
            transform: 'translate(0%, 0%)',
            width: '100%',
            height: '100%',
            padding: 0,
            border: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: '#000000a6'
          },
        }}
        shouldCloseOnOverlayClick={false}
      >
        {isMobile ?
          (
            <button className="btn btn-light btn-block" style={{
              position: 'absolute',
              top: 5,
              right: 5,
            }} onClick={closeVRModal}>྾</button>
          ) : (
            <button className="btn btn-block" style={{
              position: 'absolute',
              bottom: 15,
              left: 20,
              color: 'white'
            }} onClick={closeVRModal}>← GO BACK</button>
          )}
        <iframe src={showVR} width="100%" height="100%" frameBorder={0} id="vrview"></iframe>
      </Modal>
      <Modal
        isOpen={showLogin}
        ariaHideApp={false}
        onRequestClose={closeVRModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <ModalContent />
      </Modal>
    </div>
  );
}

export default Blocks;

import React, { Fragment, useState, useContext, useEffect } from 'react';
import DataContext from '../context/dataContext';
import Footer from '../components/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import PromoLanding from '../components/PromoLanding';
import Promo from '../components/Promo';
import DesktopSearch from '../components/DesktopSearch';
import RegisterForm from '../components/RegisterForm';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import $ from 'jquery';
import CustomFixedNavigation from '../components/CustomFixedNavigation';
import PromoOverview from '../components/PromoOverview';
const Overview = () => {
  const dataContext = useContext(DataContext);
  const { getCompanies, companies, lang } = dataContext;
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, [lang]);
  const [hide, setHide] = useState('');
  // Set key for React Fullpage
  let anchors = [];
  companies.map((company) => {
    anchors.push(company.slug);
    return anchors;
  });
  anchors.push('footerr');

  return (
    <Fragment>
      <div className='promo-slider' style={{ backgroundColor: '#1d263d' }}>
        {/* <div className='section'>
                  <PromoLanding fullpageApi={fullpageApi} />
                </div> */}

        {companies.map((company) => (
          <div className='section'>
            <PromoOverview />
            <CustomFixedNavigation
              // price={project && project.price}
              // moneyType={money}
              navigationData={[{ id: 1, name: 'OVERVIEW', to: 'overview' }, { id: 4, name: 'AMENITIES', to: 'amenities' }, { id: 5, name: 'GALLERY', to: 'gallery' }, { id: 2, name: 'INTERACTIVE MAP', to: 'interactive-map' }, { id: 3, name: 'LEASE NOW', to: 'lease-now' }]}
              backgroundColor={'#1d263d'}
            />
          </div>
        ))}
        <div>
          <div className="detail-section" style={{ backgroundColor: '#1d263d' }}>
            <div className="container-fluid pt-5">
              <div className="card card-row card-reverse">
                {/* <img src="https://alfaridah.xplor.global/api/uploads/a_91fa40da01.jpeg" alt="" /> */}
                <div className="card-body">
                  {/* <h4 className="card-title">Overview</h4> */}
                  <p className="text-justify"><em>&ldquo;Affordable, beachside homes in one of the most connected communities in Abu Dhabi.&rdquo;</em></p>
                  <p className="text-justify">Stay connected in Abu Dhabi&rsquo;s fastest-growing beachside community and experience luxury in the exclusive one, two, and three-bedroom homes of Al Faridah. Overlooking the canal from the stream of Khor al Faridah, which creates a perfect juxtaposition between the rich heritage of the past with the unique urban city.</p>
                  <p className="text-justify"><strong>Features:</strong></p>
                  <ul className="text-justify">
                    <li>Canal and Beach Views</li>
                    <li>Swimming Pool</li>
                    <li>World-Class Techno Gym</li>
                    <li>Recreational Centre</li>
                    <li>Commercial and Retail Space</li>
                  </ul>
                  <p className="text-justify">Al Zina Community is one of the most preferred destinations for potential home seekers. The community adorns vibrant culture and modern lifestyle, as well as being one of the fastest growing tier two cities in Abu Dhabi.</p>
                  <p className="text-justify"><strong>Offers:</strong></p>
                  <ul>
                    <li className="text-justify">Studios</li>
                    <li className="text-justify">1 BR</li>
                    <li className="text-justify">2 BR</li>
                    <li className="text-justify">3 BR</li>
                    <li className="text-justify">Duplex 3BR &amp; 4 BR</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section' id='section-footer'>
          <RegisterForm />
          <Footer />
        </div>
      </div>
      <FixedGetInTouchButton />
      {/* <DesktopSearch hide={hide} /> */}
    </Fragment>
  );
};

export default Overview;

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./i18next";
import App from "./App";
import Spinner from "./components/page-components/Spinner";
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-224082505-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
  <CookiesProvider>
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>
  </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import Iframe from 'react-iframe'

const CustomIframe = ({ url, height }) => {
  return (
    <Iframe url={url}
      width="100%"
      height={height}
      className="custom-iframe"
      display="initial"
      position="relative"
    />
  );
};

export default CustomIframe;

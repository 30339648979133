import React from 'react';
import { isMobile } from 'react-device-detect';
const ua = navigator.userAgent || navigator.vendor || window.opera;
const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

function HoverPopup(props) {
    console.warn("props >> ", props);
    const goToUnit = () => {
        props.setShowVR(`https://alfaridah.ae/vr-tour/${props.popupData?.block}/${props.popupData?.name}`);
        const sl = localStorage.getItem('show-sign-in');
        if (!sl || sl === 'true') {
            const loginTO = setTimeout(() => {
                if (!props.cookies['signin-provider']) {
                    props.setShowLogin(true);
                    localStorage.setItem('show-sign-in', true);
                }
                clearTimeout(loginTO);
            }, 12000);
        }
    }
    return (
        <div
            className={`hover_popup ${props.showPopup && !props.hide ? 'active' : ''} ${isInstagram ? 'hover_popup_instagram' : ''}`}
            style={{
                top: props.showPopup ? isMobile ? !isInstagram ? props.popupPosition?.y + 120 : '90%' : props.popupPosition?.y : 0,
                left: props.showPopup ? isMobile ? !isInstagram ? props.popupPosition?.x - 95 : '50%' : props.popupPosition?.x : 0,
                width: isMobile ? 150 : 250
            }}
        >
            {props.popupData?.floor !== -1 ? (
                <div className="hover_popup_content">
                    <div className="data-wrap">
                        <div>
                            <label data-lang="apartment.details.floor">Unit number</label>
                            <span>{props.popupData?.name}</span>
                        </div>
                        <div>
                            <label data-lang="apartment.details.floor">Floor</label>
                            <span>{props.popupData?.floor}</span>
                        </div>
                        <div>
                            <label data-lang="apartment.details.total_area">Sqm</label>
                            <span>{props.popupData?.sqm} m<sup>2</sup></span>
                        </div>
                        <div>
                            <label data-lang="apartment.details.room_number">Bedrooms</label>
                            <span>{props.popupData?.bedrooms}</span>
                        </div>
                        <div>
                            <label data-lang="apartment.details.room_number">Status</label>
                            <span>{props.popupData?.status === 'available' ? 'Available' : 'Leased'}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="data-wrap">
                    <div>
                        <label data-lang="apartment.details.floor">TYPE</label>
                        <span>{props.popupData?.name}</span>
                    </div>
                </div>
            )}
            <div className="button-wrap clearing">
                <button className="enter" style={{ border: 0 }} onClick={goToUnit}>
                    <label className="enter_label" data-lang="label.show_floor_plan">Explore</label>
                </button>
            </div>
        </div>
    )
}

export default HoverPopup;
import React, { Fragment } from 'react';
import EventListener, { withOptions } from 'react-event-listener';
import { NavLink } from 'react-router-dom';
import { handleScroll } from '../animations/animation';

import { Map } from 'react-lodash';

const CustomFixedNavigation = ({ navigationData, backgroundColor }) => {

  return (
    <Fragment>
      <EventListener
        target='window'
        onScroll={withOptions(handleScroll, { passive: true, capture: false })}
      />
      <nav
        className='fixed-navigation'
        style={{ backgroundColor: backgroundColor }}
        id='fixed-navigation'
      >
        <div className='container-fluid'>
          <Map
            collection={navigationData}
            iteratee={(i) => (
              <NavLink
                id={'section-nav-' + i.id}
                className={`fixed-navigation-item ${i.active === true ? 'active' : ''}`}
                key={i.id}
                to={i.to}
              >
                <b>{i.name?.toUpperCase()}</b>
              </NavLink>
            )}
          />
        </div>
      </nav>
    </Fragment>
  );
};

export default CustomFixedNavigation;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { handleOpenSearchAnimation } from '../animations/animation';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import InteractiveMap from './InteractiveMap';

const PromoInteractiveMap = ({ fullpageApi }) => {
  const { t } = useTranslation();

  const handleSearch = () => {
    handleOpenSearchAnimation();
  };
  const handleClick = () => {
    fullpageApi.moveSectionDown();
  };
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section className='promox' style={{backgroundColor: '#1d263d'}}>
        {/* <div className='promo-slide-down'>
          {t('VIEW_ALL_PROPERTIES')}
          <button
            type='button'
            className='promo-slide-down-button'
            onClick={handleClick}
          >
            <svg
              className='icon '
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              viewBox='0 0 24 24'
              width='20'
            >
              <path d='M0 0h24v24H0V0z' fill='none' />
              <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
            </svg>
          </button>
        </div> */}
        <div className='promo-contentx' style={{backgroundColor: '#1d263d'}}>
          <InteractiveMap />
          {/* <h6 className='promo-sub-title'> WELCOME TO</h6>
          <h2 className='promo-title'>
            AL FARIDAH BUILDING
          </h2>
          <hl style={{ width: 400 }} />
          <h4 className=''>AL RAHA BEACH</h4>

          <p className='promo-text'>
          {t('PROMO_1')}
          <br className='d-none d-lg-block' /> {t('PROMO_2')}
        </p>
          <NavLink
            className='btn btn-outline-light promo-action-button mt-5'
            to={'al-raha-beach'}
          >
            {'DISCOVER'}
          </NavLink> */}
        </div>
        {/* <video
        data-keepplaying
        autoPlay
        muted
        loop
        playsInline
        preload='yes'
        className='promo-video'
        poster='/assets/img/promo/al-faridah-block-c2.jpg'
      >  <source
            src="//stream.ayanaholding.com/390973231220726219099013.mp4"
            type='video/mp4'
          /> 
      </video> */}
      </section>
    </InView>
  );
};

export default PromoInteractiveMap;

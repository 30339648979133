import React, { useCallback, useEffect, useRef, useState } from 'react'
// import '../TourviewApp.css'
import { Form, Button } from 'react-bootstrap';
import md5 from 'md5';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Activation = ({ setShowLogin }) => {
    const [activationCode, setActivationCode] = useState(false);
    const [cookies, setCookie] = useCookies(['signin-data', 'signin-provider']);
    const onResolve = () => {
        if (!localStorage.getItem('ac')) {
            alert('Activation code is not valid.');
            return;
        }
        if (localStorage.getItem('ac') !== md5(activationCode)) {
            alert('Activation code is not valid.');
            return;
        }
        localStorage.setItem('signin-provider', 'self');
        localStorage.setItem('show-sign-in', false);

        setCookie('signin-provider', 'self', {domain: 'alfaridah.ae', expires: moment().add(30, 'd').toDate()});
        setShowLogin(false);
    }

    return (
        <>
            <div style={{ width: 350, maxWidth: window.innerWidth * 80 / 100 }}>
                <h4 className='title'>Xplor Available Units</h4>
                <hr />
                <Form.Group className="mb-3" controlId="formBasicActivationCode">
                    <Form.Control type="activationCode" placeholder="Activation Code" onChange={(text) => { setActivationCode(text.target.value) }} />
                </Form.Group>
                <Button variant="primary" type="button" className='w-100' onClick={onResolve}>
                    Sign in
                </Button>
            </div>
        </>
    )
}

export default Activation
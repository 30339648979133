import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import { isMobile } from 'react-device-detect';

const PromoVRTourDetails = ({ block, unit }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const onWindowResize = () => {
    clearTimeout(window.resizeLag)
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }, 200)
  }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ), [])


  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section className='promox'>
        <div className='promo-contentx' style={{ backgroundColor: '#1d263d', height: innerHeight }}>
          <iframe src={`https://vr.alfaridah.ae/${block}/${unit}/`} width={innerWidth} height={innerHeight - (isMobile ? 0 : 65)} frameBorder="0" id='vrview'></iframe>
        </div>
      </section>
    </InView>
  );
};

export default PromoVRTourDetails;

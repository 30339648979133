import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Filter from './Component/Filter';
import { publicAxios } from './Utils/services';
import { every } from 'lodash';
import Blocks from './Component/Blocks';
import { isMobile } from 'react-device-detect';
import { faArrowUp, faRedo } from '@fortawesome/free-solid-svg-icons'
import fontawesome from '@fortawesome/fontawesome'
import RotateDevice from './Component/RotateDevice';
import { useCookies } from 'react-cookie';
// import './TourviewApp.scss';

function TourviewApp() {
  console.warn("window >> ", window);
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches, [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  useLayoutEffect(() => {
    function updateLayout() {
      const isLandscape = () => window.matchMedia('(orientation:landscape)').matches
      setOrientation(isLandscape() ? 'landscape' : 'portrait');
    }
    window.addEventListener('resize', updateLayout);
    updateLayout();
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  // useEffect(() => (
  //   onWindowResize(),
  //   window.addEventListener('resize', onWindowResize),
  //   () => window.removeEventListener('resize', onWindowResize)
  // ), [])

  useEffect(() => {
    console.warn("orientation >> ", orientation);
  }, [orientation])
  fontawesome.library.add(faArrowUp, faRedo);

  const [blockIndex, setBlockIndex] = useState(0);
  const [units, setUnits] = useState([]);
  const [filters, setFilters] = useState({ bedrooms: 'all', floor: ['all'], sqm: ['all'] });
  const [filteredUnits, setFilteredUnits] = useState();
  const [playVideo, setPlayVideo] = useState(false);
  const [video, setVideo] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const VideoPlayer = useRef()
  const PromoVideoPlayer = useRef()
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

  const blocks = [
    {
      view: 'out',
      video: {
        forward: '212741077429509351033466',
      }
    },
    {
      view: 'in',
      video: {
        forward: '948185906608146573392128',
        backward: '763622591915633045266653'
      }
    },
    {
      view: 'right',
      video: {
        forward: '543852884401953434161529',
        backward: '122021874047197047322673'
      }
    },
    {
      view: 'rear',
      video: {
        backward: '685740126393323331106482'
      }
    }
  ]
  function getUnits(bi) {
    publicAxios.get(`/units?filter[view][_contains]=${blocks[bi].view}&limit=1000`).then(res => {
      setUnits(res.data.data);
    });
  }

  useEffect(() => {
    const fu = units.filter(function (item) {
      // mapKeys(filters, (value, key) => {
      const keys = Object.keys(filters);
      // console.warn("keys.length >> ", keys.length);
      let r = [];
      for (var i = 0; i < keys.length; i++) {
        if (filters[keys[i]] !== 'all' && filters[keys[i]][0] !== 'all') {
          if (typeof filters[keys[i]] === 'object') {
            if (item[keys[i]] >= filters[keys[i]][0] && item[keys[i]] <= filters[keys[i]][1])
              r.push(1);
            else
              r.push('0');
          } else {
            if (item[keys[i]] === filters[keys[i]])
              r.push(1);
            else
              r.push('0');
          }
        } else {
          r.push(1);
        }
      };
      return every(r, Number);
    });
    setFilteredUnits(fu);
  }, [filters, units])


  useEffect(() => {
    getUnits(blockIndex);
    if (PromoVideoPlayer.current)
      PromoVideoPlayer.current.play();
  }, []);

  return (
    (
      isInstagram ? (
        <div style={{
          transform: 'rotateZ(90deg)',
          transformOrigin: 'bottom left',
          width: '100vh',
          height: '100vw',
          top: '-100vw',
          position: 'absolute'
        }}>
          <video preload="yes" muted="muted" playsInline={true} style={{ zIndex: playVideo === false ? -1 : 5, opacity: playVideo === false ? '0' : '1', transition: "all .6s ease" }} className="promo-video" ref={VideoPlayer} />
          <Blocks units={filteredUnits || units} isFiltered={isFiltered} blocks={blocks} blockIndex={blockIndex} getUnits={getUnits} setBlockIndex={setBlockIndex} VideoPlayer={VideoPlayer} setPlayVideo={setPlayVideo} setVideo={setVideo} />
          <Filter setFilters={setFilters} filters={filters} setIsFiltered={setIsFiltered} />
        </div>
      ) : (
        isMobile && orientation === 'portrait' ? (
          <RotateDevice />
        ) : (
          <>
            <video preload="yes" muted="muted" playsInline={true} style={{ zIndex: playVideo === false ? -1 : 5, opacity: playVideo === false ? '0' : '1', transition: "all .6s ease" }} className="promo-video" ref={VideoPlayer} />
            <Blocks units={filteredUnits || units} isFiltered={isFiltered} blocks={blocks} blockIndex={blockIndex} getUnits={getUnits} setBlockIndex={setBlockIndex} VideoPlayer={VideoPlayer} setPlayVideo={setPlayVideo} setVideo={setVideo} />
            <Filter setFilters={setFilters} filters={filters} setIsFiltered={setIsFiltered} />
          </>
        )
      )
    )
  );
}

export default TourviewApp;

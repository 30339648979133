import React from "react";

function RotateDevice() {
    return (
        <div className="overlay">
            <div style={{
                position: 'absolute',
                top: 60,
                alignSelf: 'center',
                width: '100%',
                textAlign: 'center',
                zIndex: 2
            }}>
                <img src="assets/img/al-faridah-logo-white.png" width={130} />
            </div>

            <div className="iconContainer">
                <video preload="yes" src="assets/rotate-phone.mp4" width={(window.innerWidth * 80) / 100} height={(window.innerWidth * 80) / 100} loop playsInline autoPlay muted />
                <p>Please rotate your phone to explore available units in Al Faridah</p>
            </div>
            <div style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                width: '100%',
                textAlign: 'center'
            }}>
                <a href="https://xplor.global" target={'_blank'}><img src="assets/img/powered-by-xplor-logo.png" width={100} /></a>
            </div>
        </div>
    )
}

export default RotateDevice;
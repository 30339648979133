import React from 'react';
import * as Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';

const DesktopGetInTouchBotton = () => {
  const { t } = useTranslation();

  let scroll = Scroll.animateScroll;

  const handleClick = () => {
    scroll.scrollToBottom();
  };

  return (
    <div id='desktop-get-in-touch-action' onClick={handleClick}>
      <button id='get-in-touch-button'>
        {t('GET_IN_TOUCH')}
        <svg className='icon icon-long-arrow-circle'>
          <path
            d='M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 Z M24.3437362,16 L23.9419501,16 C23.6756883,16 23.4598067,16.2158816 23.4598067,16.4821434 L23.4598067,31.5847512 L20.1071288,28.2126511 C19.91887,28.0243923 19.6136067,28.0243923 19.4252695,28.2126511 L19.1411941,28.4967657 C18.9529353,28.6850245 18.9529353,28.990327 19.1411941,29.1785858 L23.8019135,33.8588059 C23.9902115,34.0470647 24.2954748,34.0470647 24.483812,33.8588059 L29.1445314,29.1785858 C29.3327902,28.990327 29.3327902,28.6850245 29.1444922,28.4967657 L28.8604168,28.2126511 C28.6721188,28.0243923 28.3668555,28.0243923 28.1785575,28.2126511 L24.8258796,31.5847512 L24.8258796,16.4821434 C24.8258796,16.2158816 24.609998,16 24.3437362,16 Z'
            id='Combined-Shape'
            fill='#fff'
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default DesktopGetInTouchBotton;

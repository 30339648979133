import React, { Fragment, useEffect, useState } from 'react';
import EventListener, { withOptions } from 'react-event-listener';
import { useTranslation } from 'react-i18next';
import {
  handleScroll,
} from '../animations/animation';

import { Map } from 'react-lodash';
import { PopupModal } from 'react-calendly';
import Axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const VRTourFixedNavigation = ({ block, unit, navigationData, backgroundColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [connectedUnits, setConnectedUnits] = useState(false);
  const [cookies, setCookie] = useCookies(['signin-data', 'signin-provider']);
  const signinData = JSON.parse(localStorage.getItem('signin-data'));

  const { t } = useTranslation();
  useEffect(() => {
    Axios.get(`https://xplor.alfaridah.ae/api/items/units?filter[name][_eq]=${unit}`).then((result) => {
      setConnectedUnits(result.data.data[0]);
    });
  }, [])
  return (
    <Fragment>
      <EventListener
        target='window'
        onScroll={withOptions(handleScroll, { passive: true, capture: false })}
      />
      <nav
        className='fixed-navigation'
        style={{ backgroundColor: backgroundColor }}
        id='fixed-navigation'
      >
        {/* <button onClick={() => window.close()} className='back-to-dev'>
          <div className='back-to-dev-link-title'>
            <svg className='icon icon-long-arrow-left'>
              <path
                d='M11.531223,8.876426 L15.626401,4.798311 C15.791127,4.63355 15.791127,4.366446 15.626401,4.201651 L11.531223,0.12357 C11.366497,-0.04119 11.099358,-0.04119 10.934632,0.12357 L10.686033,0.372135 C10.521307,0.536896 10.521307,0.804 10.686033,0.96876 L13.63661,3.902343 L0.421874,3.902343 C0.188896,3.902343 0,4.091239 0,4.324217 L0,4.675779 C0,4.908757 0.188896,5.097653 0.421874,5.097653 L13.63661,5.097653 L10.686033,8.031236 C10.521307,8.195962 10.521307,8.463066 10.686033,8.627861 L10.934632,8.876426 C11.099358,9.041152 11.366497,9.041152 11.531223,8.876426 Z'
                id='long-arrow-left'
                fill='#fff'
                fillRule='nonzero'
                transform='translate(7.874973, 4.499985) scale(-1, 1) translate(-7.874973, -4.499985) '
              ></path>
            </svg>
            {t('GO BACK')}
          </div>
        </button> */}
        <div className='container-fluid'>
          <Map
            collection={connectedUnits && connectedUnits.connected_units}
            iteratee={(i) => (
              <NavLink
                id={'section-nav-' + i}
                className='fixed-navigation-item'
                key={i.id}
                to={`/vr-tour/${i}`}
              >
                {i.toUpperCase()}
              </NavLink>
            )}
          />
        </div>
        <div className='starting-price' style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <div
            style={{
              cursor: 'pointer',
              marginRight: 25
            }}
            onClick={() => {
              setIsOpen(true);
              console.warn("cookies >> ", cookies);
            }}
          >
            <h6 style={{
              fontWeight: 'bold',
              color: 'white'
            }}>BOOK A VIEWING</h6>
          </div>
          <div
            style={{
              cursor: 'pointer'
            }}
            onClick={() => {
              window.open('https://ochomefurniture.com/?utm_source=Ayana&utm_medium=Referral&utm_campaign=ayana_oc_collab&utm_content=XPLOR');
            }}
          >
            <h6 style={{
              fontWeight: 'bold',
              color: 'white'
            }}>BUY FURNITURE</h6>
          </div>
        </div>
      </nav>
      <PopupModal
        url="https://calendly.com/alfaridah/unit-viewing"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        prefill={{
          name: signinData ? ((signinData.firstname && signinData.lastname) && `${signinData.firstname} ${signinData.lastname}` || signinData.name || `${signinData.localizedFirstName} ${signinData.localizedLastName}`) : '',
          email: signinData ? (signinData.email || '') : '',
          customAnswers: {
            Block: block,
            Unit: unit
          },
          Block: block,
          Unit: unit,
          block: block,
          unit: unit
        }}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </Fragment>
  );
};

export default VRTourFixedNavigation;

import React, { useEffect, useState } from 'react';
import { useSpring, animated } from "@react-spring/web";
import { NavLink } from 'react-router-dom';

function Pin(props) {
	return (
		<>
			<g transform={props.transform}>
				<animated.g
					style={props.animation[`${props.name}-active`]}
					onClick={() => {
						props.passivate()
					}}
				>
					<image
						width={540}
						height={717}
						xlinkHref={`/assets/pins/${props.name}-active.png`}
						overflow="visible"
					/>
				</animated.g>
			</g>
			<animated.g
				style={props.animation[props.name]}
				onMouseOver={() => props.active !== props.name && props.api[props.name].start({ opacity: 1 })}
				onMouseLeave={() => props.active !== props.name && props.api[props.name].start({ opacity: .6 })}
				onClick={() => {
					props.activate(props.name)
				}}
			>
				<image
					width={540}
					height={717}
					xlinkHref={`/assets/pins/${props.name}.png`}
					transform={props.transform}
					overflow="visible"
				/>
			</animated.g>
		</>
	)

}

function Distance(props) {
	// console.warn("animation >> ", transform, "api >> ", api);
	return (
		<>
			<g transform={props.transform}>
				<animated.g
					style={props.animation[`${props.name}-distance`]}
					onClick={() => {
						props.passivate()
					}}
				>
					<image
						width={props.width}
						height={props.height}
						xlinkHref={`/assets/distances/${props.name}.png`}
						overflow="visible"
					/>
				</animated.g>
			</g>
		</>
	)

}

function AnimatedPath(props) {
	return (
		<animated.path
			fill="none"
			stroke="#FFF"
			strokeLinecap="round"
			strokeLinejoin="round"
			style={{
				opacity: props.active === props.name ? 1 : 0
			}}
			strokeDasharray={props.active === props.name ? props.strokeDash : 0}
			strokeDashoffset={props.animation[`${props.name}-line`]['x'].to(x => {
				return (x * props.strokeDash);
			})}
			strokeWidth="10"
			d={props.d}
		/>
	)
}

function InteractiveMap(props) {
	const [active, setActive] = useState();
	const [isMobile, setMobile] = useState(false);
	useEffect(() => {
		if (window.innerWidth > window.innerHeight) {
			setMobile(false);
		} else {
			setMobile(true);
		}
		window.addEventListener('resize', () => {
			console.warn("window.innerWidth >> ", window.innerWidth);
			if (window.innerWidth > window.innerHeight) {
				setMobile(false);
			} else {
				setMobile(true);
			}
		});
	}, []);
	let animation = [], api = [];

	[animation['abu-dhabi-airport'], api['abu-dhabi-airport']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['abu-dhabi-downtown'], api['abu-dhabi-downtown']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['al-hudayriyat-island'], api['al-hudayriyat-island']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['al-reem-island'], api['al-reem-island']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['grand-mosque'], api['grand-mosque']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['louvre-abu-dhabi'], api['louvre-abu-dhabi']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['saadiyat-island'], api['saadiyat-island']] = useSpring(() => ({ from: { opacity: .6 } }));
	[animation['yas-mall'], api['yas-mall']] = useSpring(() => ({ from: { opacity: .6 } }));

	[animation['abu-dhabi-airport-active'], api['abu-dhabi-airport-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['abu-dhabi-downtown-active'], api['abu-dhabi-downtown-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['al-hudayriyat-island-active'], api['al-hudayriyat-island-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['al-reem-island-active'], api['al-reem-island-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['grand-mosque-active'], api['grand-mosque-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['louvre-abu-dhabi-active'], api['louvre-abu-dhabi-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['saadiyat-island-active'], api['saadiyat-island-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));
	[animation['yas-mall-active'], api['yas-mall-active']] = useSpring(() => ({ from: { opacity: 0, scale: 1, y: 0, x: 0 } }));

	animation['abu-dhabi-airport-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['abu-dhabi-downtown-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['al-hudayriyat-island-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['al-reem-island-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['grand-mosque-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['louvre-abu-dhabi-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['saadiyat-island-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 2500 } });
	animation['yas-mall-line'] = useSpring({ reset: true, reverse: true, from: { x: 0, opacity: 0 }, x: 1, config: { duration: 1000 } });

	[animation['abu-dhabi-airport-distance'], api['abu-dhabi-airport-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['abu-dhabi-downtown-distance'], api['abu-dhabi-downtown-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['al-hudayriyat-island-distance'], api['al-hudayriyat-island-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['al-reem-island-distance'], api['al-reem-island-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['grand-mosque-distance'], api['grand-mosque-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['louvre-abu-dhabi-distance'], api['louvre-abu-dhabi-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['saadiyat-island-distance'], api['saadiyat-island-distance']] = useSpring(() => ({ from: { opacity: 0 } }));
	[animation['yas-mall-distance'], api['yas-mall-distance']] = useSpring(() => ({ from: { opacity: 0 } }));

	function activate(obj) {
		if (active) {
			passivate()
		}
		api[obj].start({ opacity: 0, display: 'none' });
		api[`${obj}-distance`].start({ opacity: 1 });
		api[`${obj}-active`].start({ config: { duration: 250 }, opacity: 1, scale: 1.2, y: -150, x: -50 });
		animation[`${obj}-line`]['x'].start({ opacity: 1 })
		setActive(obj);
	}

	function passivate() {
		api[active].start({ opacity: .6, display: '' });
		api[`${active}-distance`].start({ opacity: 0 });
		api[`${active}-active`].start({ config: { duration: 250 }, opacity: 0, scale: 1, y: 0, x: 0 });
		animation[`${active}-line`]['x'].start({ opacity: 0 })
		setActive();
	}

	console.warn("animation >> >> >> ", animation);
	const [afb_styles, afb_api] = useSpring(() => ({ from: { opacity: 1 } }));
	const [afbw_styles, afbw_api] = useSpring(() => ({ from: { opacity: 0 } }));
	return (
		!isMobile ? (
			<div style={{
				justifyContent: 'center',
			}}>
				<svg
					id="prefix__Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 3392 1816"
					preserveAspectRatio="xMinYMid meet"
					xmlSpace="preserve"
					{...props}
					style={{
						width: '100%',
						height: '100vh'
					}}
				>
					<image
						preserveAspectRatio="none"
						xlinkHref="/assets/map.jpeg"
						overflow="visible"
						onClick={() => {
							if (active) {
								passivate()
							}
						}}
					/>

					<AnimatedPath name="yas-mall" strokeDash="500" animation={animation} active={active} d="M2587.1,1323.7c0,0,23.7,23.7,52.9-26l93.5-85.3l-52.9-63.4c0,0,1.8-30.5-26.9-22.8c0,0-53.3,4.1-43.3-38.8c0,0,8.2-24.2-33.3-38.3l-19.9,28" />
					<AnimatedPath name="abu-dhabi-airport" strokeDash="1500" animation={animation} active={active} d="M2582.6 1329.9c2 1.7 18.5 22.8 19.8 27.4s42.3 60.1 51.5 81.2 34.4 53.5 83.9 66.1c0 0 35.7 9.9 103.1 74.6 0 0 7.3 5.9 11.2 3.3s40.9-38.3 40.9-40.3-28.4-23.8-28.4-27.1 65.4-59.8 63.4-58.6" />
					<AnimatedPath name="abu-dhabi-downtown" strokeDash="2500" animation={animation} active={active} d="M2582.6 1324.2s18.1 11.2 15 13.5-13.5 4.7-15 4.4-75 5.4-81.4 5.1-16.6-1-20-1.3c-2.4-.2-10.3-1.2-14.5-1.7-1.4-.2-2.7 1-2.7 2.4v15.4c0 1.4-1.1 2.5-2.4 2.6-46.3 3.3-741.3 52.8-750.9 52.1-9.9-.7-160.5-25.8-167.8-27.7s-39-10.6-50.9-14.5c-11.9-4-138.1-44.3-142-45.6s-27.7-21.8-39-31.7c-11.2-9.9-76.6-113.6-177-143.3 0 0-35.7-18.5-76.6-20.5-41-2-57.5 4.6-66.1 0s-25.1-4.6-46.9-18.5-57.5-31.7-57.5-33.7-13.2-1.3-21.8-68-25.8-143.3-25.8-148-13.9-35-15.2-37-15.8-44.3-17.8-46.2c-2-2-7.3-17.2-9.9-18.5s-41.6 9.9-44.3 9.9" />
					<AnimatedPath name="al-reem-island" strokeDash="2400" animation={animation} active={active} d="M2582.6 1324.2s18.1 11.2 15 13.5-13.5 4.7-15 4.4-75 5.4-81.4 5.1-16.6-1-20-1.3c-2.4-.2-10.3-1.2-14.5-1.7-1.4-.2-2.7 1-2.7 2.4v15.4c0 1.4-1.1 2.5-2.4 2.6-46.3 3.3-741.3 52.8-750.9 52.1-9.9-.7-160.5-25.8-167.8-27.7s-39-10.6-50.9-14.5c-11.9-4-138.1-44.3-142-45.6s-27.7-21.8-39-31.7c-11.2-9.9-76.6-113.6-177-143.3 0 0-35.7-18.5-76.6-20.5-41-2-57.5 4.6-66.1 0s-25.1-4.6-46.9-18.5-57.5-31.7-57.5-33.7-13.2-1.3-21.8-68-25.8-143.3-25.8-148c0 0-1.1-3.1 18.7-4.8 19.8-1.8 60.3.9 81.5-39.6l11.8-14.4c1.2-1.4 3-2 4.8-1.6 8.7 2.3 34.4 9.5 42.4 16.4 9.7 8.4 26.4 20.7 28.6 23.8 1.8 2.5 16.6 9.9 21.6 12.4 1 .5 2.2.2 2.9-.6 3.5-4.1 13.1-15.5 12.4-16.6 0 0-17.7-6.1-26-6.9 0 0-2.5 2.5-3.8 5.1s10.4 5.6 10.4 5.6" />
					<AnimatedPath name="al-hudayriyat-island" strokeDash="2800" animation={animation} active={active} d="M2582.6 1324.2s18.1 11.2 15 13.5-13.5 4.7-15 4.4-75 5.4-81.4 5.1-16.6-1-20-1.3c-2.4-.2-10.3-1.2-14.5-1.7-1.4-.2-2.7 1-2.7 2.4v15.4c0 1.4-1.1 2.5-2.4 2.6-46.3 3.3-741.3 52.8-750.9 52.1-9.9-.7-160.5-25.8-167.8-27.7s-39-10.6-50.9-14.5c-11.9-4-138.1-44.3-142-45.6s-27.7-21.8-39-31.7c-11.2-9.9-76.6-113.6-177-143.3 0 0-35.7-18.5-76.6-20.5-41-2-57.5 4.6-66.1 0s-25.1-4.6-46.9-18.5-56-31.7-57.5-33.7c0 0-13.3-9.1-19.4-52l-204.2 70.1s-141.7 45.9-191.9 70.7c-43.7 21.6-85.1 39.8-95.1 44.2-1.3.6-1.8 2-1.2 3.3l2.2 4.7c.6 1.2 2.1 1.8 3.3 1.2l40.7-19.7s5.9-1.3-3.3-19.8c-6.8-13.6-18.2-31.6-23.8-40.2-1.1-1.6-3.6-1.2-4 .7-2.3 10.3-11.9 31.9-52.1 45.4-54.2 18.2-65.7 4.9-65.7 4.9" />
					<AnimatedPath name="louvre-abu-dhabi" strokeDash="2500" animation={animation} active={active} d="M2587.9 1321.6s12.6 21.8 35.7-2 103.1-101.7 111-107.7c0 0 18.5 10.6 20.8 15.2l76.9-60.8s24.4.7 34.4 4c9.9 3.3 44.9-15.8 8.6-31.7 0 0-25.1-10.6-54.2-4.6-29.1 5.9-220.6-90.7-260.9-104.8s-378.5-116.5-428-142.9-347.4-146-347.4-146-99.6-15.2-109.6-86.5c0 0-9.3-37.7-118.3-58.1 0 0-23.1-4.6-32.4-6.6-9.2-2-21.1-2-25.8-2s-25.1 2.6-35 5.9c0 0-10.6 4.6-19.8 7.9s-11.2-1.3-29.1 1.3-54.8 5.9-83.9 33.7c0 0-5.3 7.3-18.5 9.9s-9.2 11.9-17.2 12.5c-7.9.7-29.7-9.9-38.3-12.5-8.4-2.6-65.6-22.7-119.2.1-2.4 1-5.2 0-6.5-2.3l-22.9-44-6.6 2" />
					<AnimatedPath name="saadiyat-island" strokeDash="2200" animation={animation} active={active} d="M2587.9 1321.6s12.6 21.8 35.7-2 103.1-101.7 111-107.7c0 0 18.5 10.6 20.8 15.2l76.9-60.8s24.4.7 34.4 4c9.9 3.3 44.9-15.8 8.6-31.7 0 0-25.1-10.6-54.2-4.6-29.1 5.9-220.6-90.7-260.9-104.8s-378.5-116.5-428-142.9-347.4-146-347.4-146-99.6-15.2-109.6-86.5c0 0-9.3-37.7-118.3-58.1 0 0-23.1-4.6-32.4-6.6-9.2-2-21.1-2-25.8-2 0 0-14.5-.7-7.9-8.6s22.5 5.9 4.6 15.8-11.2 5.9-25.1 8.6-72 2.6-90.5 14.5-23.1 15.2-21.8 29.7c0 0 9.9 7.3 15.2 8.6" />
					<AnimatedPath name="grand-mosque" strokeDash="2000" animation={animation} active={active} d="M2582.6 1324.2s18.1 11.2 15 13.5-13.5 4.7-15 4.4-75 5.4-81.4 5.1-16.6-1-20-1.3c-2.4-.2-10.3-1.2-14.5-1.7-1.4-.2-2.7 1-2.7 2.4v15.4c0 1.4-1.1 2.5-2.4 2.6-46.3 3.3-741.3 52.8-750.9 52.1 0 0-83.9 4.3-117.6 16.2s-48.6 19.8-85.2-6.9-53.5-41.9-53.5-41.9-25.8-11.6-66.4-5.6c-25.3 3.7-39.1 5-45.9 5.4-3.4.2-6.3 2.5-7.4 5.8l-11.1 35.4-25.8-3" />

					<Pin transform="matrix(.24 0 0 .24 2490.96 908.32)" name="yas-mall" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 2866.8 1288)" name="abu-dhabi-airport" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 1232.88 1258.96)" name="grand-mosque" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 1309.68 487.12)" name="saadiyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 1037.28 433.84)" name="louvre-abu-dhabi" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 977.04 674.08)" name="al-reem-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 687.84 605.44)" name="abu-dhabi-downtown" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(.24 0 0 .24 207.6 1029.04)" name="al-hudayriyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />

					<Distance transform="matrix(.24 0 0 .24 2415.12 1143.04)" width={932} height={108} name="yas-mall" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 2456.64 1520.8)" width={890} height={108} name="abu-dhabi-airport" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 1844.4 1441.12)" width={959} height={107} name="grand-mosque" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 1972.8 760)" width={994} height={108} name="saadiyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 1496.4 538.24)" width={1003} height={107} name="louvre-abu-dhabi" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 968.16 878.08)" width={987} height={107} name="al-reem-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 1192.56 1118.32)" width={1000} height={108} name="abu-dhabi-downtown" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(.24 0 0 .24 634.56 1122.64)" width={986} height={108} name="al-hudayriyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />

					<NavLink to={'lease-now'}>
						<animated.g
							style={afbw_styles}
							onMouseLeave={() => {
								afb_api.start({ config: { duration: 250 }, opacity: 1 })
								afbw_api.start({ config: { duration: 250 }, opacity: 0 })
							}}
						>
							<image
								width={420}
								height={420}
								xlinkHref="/assets/pins/al-faridah-building-white.png"
								transform="matrix(.24 0 0 .24 2540.16 1282.72)"
								overflow="visible"
							/>
						</animated.g>
						<animated.g
							style={afb_styles}
							onMouseOver={() => {
								console.warn("afb_api >> ", afb_api);
								afb_api.start({ config: { duration: 250 }, opacity: 0 })
								afbw_api.start({ config: { duration: 250 }, opacity: 1 })
							}}
							onMouseLeave={() => {
								afb_api.start({ config: { duration: 250 }, opacity: 1 })
								afbw_api.start({ config: { duration: 250 }, opacity: 0, scale: 1 })
							}}
						>
							<image
								width={420}
								height={420}
								xlinkHref="/assets/pins/al-faridah-building.png"
								transform="matrix(.24 0 0 .24 2540.16 1282.72)"
								overflow="visible"
							/>
						</animated.g>
					</NavLink>
				</svg>
			</div>
		) : (
			<div style={{
				justifyContent: 'center',
				height: '100vh',
				overflow: 'hidden'
			}}>
				<svg
					id="prefix__Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="-40 30 1360 3685"
					preserveAspectRatio="xMinYMid meet"
					xmlSpace="preserve"
					{...props}
					style={{
						width: '100%',
					}}
				>
					<image
						preserveAspectRatio="none"
						width="1480"
						y={20}
						x={-50}
						xlinkHref="/assets/map-mobile.png"
						overflow="visible"
						onClick={() => {
							if (active) {
								passivate()
							}
						}}
					/>

					<AnimatedPath name="yas-mall" strokeDash="500" animation={animation} active={active} d="M1070,1401.3l70.5-64.8l-13.8-20.2c0,0-2.8-9.7-4.2-13c-1.4-3.4-21.1-0.1-29.8-10.4c-7.4-8.8-6.7-16-9.3-22.1c-1.7-4.2-8.2-7.2-15.2-9.9" />
					<AnimatedPath name="abu-dhabi-airport" strokeDash="1500" animation={animation} active={active} d="M1066.9,1431.2c0.8,0.7,7.8,9.6,8.3,11.5c0.6,1.9,17.7,25.2,21.6,34.1s14.4,22.4,35.2,27.7c0,0,15,4.2,43.2,31.3c0,0,3,2.5,4.7,1.4s17.2-16.1,17.2-16.9s-11.9-10-11.9-11.4s10.4-9.8,9.5-9.3" />
					<AnimatedPath name="abu-dhabi-downtown" strokeDash="2500" animation={animation} active={active} d="M1021.1,1415.6h-40.6c0,0.6,6,7.4,5.4,7.4c0.1,0.3-283.9,10.1-283.9,9.8c-0.1-3.5-51.2-13.6-56.7-15.4c-5.6-1.9-61.8-20.4-63.7-21c-15.9-4.8-32.9-34.3-38.1-38.9c-4.9-4.3-31.2-44.9-71.3-64c-5.1-2.4-9.8-5.5-14-9.3c-5.5-5.1-14.6-11.9-25.1-14.2c-1.7-0.4-3.5-0.6-5.3-0.7c-18.2-0.8-35.1-1.2-38.9-3.3c-4-2.2-2.6-1.1-12.8-7.6c-10.2-6.4-24.3-16-25-16.9c0,0-9.2-7.5-15.5-19.1c-1.5-2.7-3.4-4.7-5.8-6.2l-0.1-0.1c-0.5-1.7-5.6-19.8-10.2-58.4c-4.4-37.1-26.4-89.8-44.6-117.9c-2.2-3.4-6.3-5.1-10.2-4.3l-29,5.9" />
					<AnimatedPath name="al-reem-island" strokeDash="2400" animation={animation} active={active} d="M1021.1,1415.6h-40.6c0,0.6,6,7.4,5.4,7.4c0.1,0.3-283.9,10.1-283.9,9.8c-0.1-3.5-51.2-13.6-56.7-15.4c-5.6-1.9-61.8-20.4-63.7-21c-15.9-4.8-32.9-34.3-38.1-38.9c-4.9-4.3-31.2-44.9-71.3-64c-5.1-2.4-9.8-5.5-14-9.3c-19.2-21.7-53.5-12.7-69.3-18.2c-5.3-2.4-37.4-23.4-37.8-24.5c0,0-9.2-7.5-15.5-19.1c-1.5-2.7-3.5-4.7-5.9-6.3c-4.3-7.8-12.4-90.9-25.8-116.8c2.6-6.5,10.6,4.7,25.8,2.4c15.6-1.9,15.7-17.1,32.1-16c10.6,0.8,20.7,12.2,26.5,20.2c5.8,8.2,18.7-0.6,13.3-9c0,0-4.5-7.4-4.5-7.4" />
					<AnimatedPath name="al-hudayriyat-island" strokeDash="2800" animation={animation} active={active} d="M1021.1,1415.6h-40.6c0,0.6,6,7.4,5.4,7.4c0.1,0.3-283.9,10.1-283.9,9.8c-0.1-3.5-51.2-13.6-56.7-15.4c-5.6-1.9-61.8-20.4-63.7-21c-15.9-4.8-32.9-34.3-38.1-38.9c-4.9-4.3-31.2-44.9-71.3-64c-5.1-2.4-9.8-5.5-14-9.3c-5.5-5.1-14.6-11.9-25.1-14.2c-1.7-0.4-3.5-0.6-5.3-0.7c-18.2-0.8-35.1-1.2-38.9-3.3c-4-2.2-2.6-1.1-12.8-7.6c-10.2-6.4-24.3-16-25-16.9c0,0-9.2-7.5-15.5-19.1c-1.3-2.3-4.1-5.2-5.9-6.3l0,0c-5.1-3.3-11.6-3.9-17.5-1.2l-67.2,31.6c0,0-64.3,24.9-87.7,36.5c-6.7,3.3-13.3,6.4-19.3,9.2l-0.5,0.2c-12,5.6-21.5,9.8-24.6,11.1c-0.6,0.2-0.9,0.9-0.6,1.5l1,2.2c0.2,0.6,0.9,0.9,1.5,0.6l23.4-9.2c0,0,2.2-0.5-0.2-6.4l0-0.1c-0.3-0.8-0.7-1.7-1.2-2.7c-3.2-6.4-8.5-14.8-11.1-18.8c-0.5-0.8-1.7-0.6-1.9,0.3c-1.1,4.8,4.9,7.1-13.9,13.4c-20.6,6.1-35.6-12-35.6-12" />
					<AnimatedPath name="louvre-abu-dhabi" strokeDash="2500" animation={animation} active={active} d="M1070,1401.3c4.8-3.6,70.6-64.7,70.6-64.7l0,0l11.3,9.8l27.6-18.8c0,0,8,4.3,17.6,3.5s14.6-12.8,10.9-18.5c-3.7-5.8-17.4,1.6-27.2-4.1c0,0-42.1-13.2-77.1-47.5c-35-34.4-121.6-67.9-168.1-97s-170.8-113.1-192.9-129.7c0,0-31.7-15.7-43.3-58.6s-76.9-67.3-109.1-64.9c-32.2,2.4-70.7,58-84.5,54.5s-46.6-34.8-80-11.6l-19.6-23.3" />
					<AnimatedPath name="saadiyat-island" strokeDash="2500" animation={animation} active={active} d="M1070,1401.3c4.8-3.6,70.6-64.7,70.6-64.7l0.1,0.1l11.2,9.7l27.6-18.8c0,0,8,4.3,17.6,3.5s14.6-12.8,10.9-18.5c-3.7-5.8-17.4,1.6-27.2-4.1c0,0-42.1-13.2-77.1-47.5c-35-34.4-121.6-67.9-168.1-97s-170.8-113.1-192.9-129.7c0,0-31.7-15.7-43.3-58.6l-18-29.7c0,0-13.6-16.9-22.9-18.9c-9.2-2-17.5-8.7-22.1-8.7c0,0-14.5-0.7-7.9-8.6c6.6-7.9,22.5,5.9,4.6,15.8c-17.8,9.9-11.2,5.9-25.1,8.6s-10.9-6.2-29.4,5.7s-23.1,15.2-21.8,29.7c0,0,9.9,7.3,15.2,8.6" />
					<AnimatedPath name="grand-mosque" strokeDash="2000" animation={animation} active={active} d="M1021.1,1415.6h-40.6c0,0.6,6,7.4,5.4,7.4c0.1,0.3-283.9,10.1-283.9,9.8h-0.3c0,0-20.1,4-29.2,0c-4.5-2-12.1-1.7-20.1,0.6c-15.8,4.7-33,1.3-45.4-9.6c-5.7-5.1-11.4-9.5-15.4-11.4c-11.9-5.7-25.6-2.3-32.4-5.1l-17.6-1.4l-19.3,8.7" />

					<Pin transform="matrix(0.24 0 0 0.24 1016.88 1129.8)" name="yas-mall" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 1142.16 1364.52)" name="abu-dhabi-airport" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 469.2 1287.7201)" name="grand-mosque" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 521.76 845.8801)" name="saadiyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 354.72 801.72)" name="louvre-abu-dhabi" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 347.28 962.28)" name="al-reem-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 186.48 912.1201)" name="abu-dhabi-downtown" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Pin transform="matrix(0.24 0 0 0.24 24 1141.0801)" name="al-hudayriyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />

					<Distance transform="matrix(1 0 0 1 695.9402 1308.9121)" width={355} height={40} name="yas-mall" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 765.5344 1529.5669)" width={360} height={40} name="abu-dhabi-airport" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 604.9917 1463.7285)" width={380} height={40} name="grand-mosque" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 766.6301 964.5984)" width={400} height={40} name="saadiyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 689.6262 897.3506)" width={400} height={40} name="louvre-abu-dhabi" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 490.1431 1150.9216)" width={390} height={40} name="al-reem-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 411.7939 1145.5488)" width={400} height={40} name="abu-dhabi-downtown" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<Distance transform="matrix(1 0 0 1 71.9397 1325.5659)" width={390} height={40} name="al-hudayriyat-island" animation={animation} api={api} activate={activate} passivate={passivate} active={active} />
					<NavLink to={'lease-now'}>
						<image
							width={420}
							height={420}
							xlinkHref="/assets/pins/al-faridah-building.png"
							transform="matrix(0.24 0 0 0.24 990.24 1359.7201)"
							overflow="visible"
						/>
					</NavLink>
				</svg>
			</div>
		)
	);
}

export default InteractiveMap;

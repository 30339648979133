import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className='notfound'>
      <h1>{t('NOT_FOUND')}</h1>
      <NavLink to='/'>
        <h3>{t('GO_HOME')}</h3>
      </NavLink>
    </div>
  );
};

export default NotFound;

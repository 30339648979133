import React from "react";

const Card = ({ cardclassName, image, title, description }) => {
  return (
    <div className={`card ${cardclassName ? cardclassName : ""}`}>
      <img src={process.env.REACT_APP_API_URL + image.url} alt='' />
      <div className='card-body'>
        <h4 className='card-title'>{title}</h4>
        <p className='card-text'>{description}</p>
      </div>
    </div>
  );
};

export default Card;

import React, { useState } from 'react';
import './styles/main.scss';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import MobileMenu from './components/MobileMenu';
import MobileSearch from './components/MobileSearch';
import Header from './components/Header';
import DataState from './context/DataState';
import Media from './pages/Media';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/TermsAndConditions';
import StaticPages from './pages/StaticPages';
import Overview from './pages/Overview';
import InteractiveMap from './pages/InteractiveMap';
import VRTour from './pages/VRTour';
import VRTourDetails from './pages/VRTourDetails';
import Amenities from './pages/Amenities';
import Gallery from './pages/Gallery';
import Tourview from './pages/Tourview';
import ZohoSalesIQ from './components/ZohoSalesIQ';

const App = () => {
  // Global Open Menu State
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <DataState>
      <BrowserRouter>
        <Header open={open} handleOpen={handleOpen} />
        <Switch>
          <Route exact path='/' component={Tourview} />
          <Route exact path='/projects' component={Projects} />
          <Route exact path='/projects/:company' component={Projects} />
          <Route
            exact
            path='/projects/:company/:project'
            component={ProjectDetails}
          />
          <Route exact path='/overview' component={Overview} />
          <Route exact path='/amenities' component={Amenities} />
          <Route exact path='/gallery' component={Gallery} />
          <Route exact path='/interactive-map' component={InteractiveMap} />
          <Route exact path='/lease-now' component={Tourview} />
          <Route exact path='/vr-tour/:block/:unit' component={VRTourDetails} />
          <Route exact path='/vr-tours' component={VRTour} />
          <Route exact path='/:page' component={StaticPages} />
          <Route exact path='/media' component={Media} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route
            exact
            path='/terms-and-conditions'
            component={TermsAndConditions}
          />
        </Switch>
        <MobileMenu open={open} handleOpen={handleOpen} />
        <MobileSearch />
        <ZohoSalesIQ />
      </BrowserRouter>
    </DataState>
  );
};

export default App;

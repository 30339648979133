import React, { useContext, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HamburgerMenu from 'react-hamburger-menu';
import DataContext from '../context/dataContext';
import { handleMenuAnimation } from '../animations/animation';
import { isMobile } from 'react-device-detect';
const Header = ({ open, handleOpen }) => {
  let history = useHistory();

  const dataContext = useContext(DataContext);
  const { staticPages } = dataContext;

  let mainMenu = [];
  if (staticPages) {
    staticPages.map((item) => {
      if (item.show_in_menu === true) {
        mainMenu.push(item);
      }
      return mainMenu; // I added return
    });
  }

  return (
    <>
      {history.location.pathname.indexOf('/vr-tour/') === -1 && (
        <header id='header'>
          <HamburgerMenu
            className='hamburger-menu'
            isOpen={open}
            menuClicked={() => {
              handleMenuAnimation(open);
              handleOpen();
            }}
            width={18}
            height={15}
            strokeWidth={1}
            rotate={0}
            color='white'
            borderRadius={0}
            animationDuration={0.5}
          />

          <nav className='navigation' id='header-navigation-left'>
            {mainMenu.length > 0 && (
              <button className='menu-toggle-button'>
                <span></span>
                <span></span>
                <span></span>
              </button>
            )}
            {mainMenu.length > 0 && (
              <ul>
                {mainMenu.map((item) => (
                  <li key={item.id}>
                    <NavLink to={`/${item.slug}`}>{item.name}</NavLink>
                  </li>
                ))}
              </ul>
            )}
          </nav>
          {(!isMobile && history.location.pathname !== '/') && (
            history.location.pathname !== '/lease-now') && (
              <div id='logo'>
                <NavLink to='/'>
                  {/* <b>AL FARIDAH</b> BUILDING */}
                  <img src="/assets/img/dba-logo.png" width="250" />
                </NavLink>
              </div>
            )}
          <div id='header-hover-handler'></div>
        </header>
      )}
    </>
  );
};

export default Header;

import React from 'react';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import { NavLink } from 'react-router-dom';

const PromoVRTour = ({ fullpageApi }) => {
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section className='vr-promo'>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-c3-unit-802-3-br.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: C3 | UNIT: 802</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              1 BEDROOM
            </h4>
            <h5 className=''>74 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-c3-unit-802-1-br'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-c1-unit-1203-2-br.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: C1 | UNIT: 1203</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              1 BEDROOM
            </h4>
            <h5 className=''>84 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-c1-unit-1203-1-br'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-c2-unit-1208-2-br-m.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: C2 | UNIT: 1208</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              2 BEDROOM
            </h4>
            <h5 className=''>144 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-c2-unit-1208-2-br-m'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-c3-unit-808-3-br.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: C3 | UNIT: 808</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              3 BEDROOM
            </h4>
            <h5 className=''>194 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-c3-unit-808-3-br'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        <div className='text-center p-0 mt-0'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-a-unit-502-2-br.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: A | UNIT: 502</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              3 BEDROOM DUPLEX
            </h4>
            <h5 className=''>208 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-a-unit-502-3-br'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        <div className='text-center'>
          <div className="slide"
            style={{
              background: 'url(assets/img/vrtour/block-podium-unit-205-3-br-m-duplex.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            {/* <h6 className='promo-sub-title'> BLOCK: PODIUM | UNIT: 205</h6> */}
            <img src="/assets/img/al-faridah-logo-white.png" alt="" height="200" />
            <h4 className='promo-titlex' style={{ opacity: 1, fontWeight: 'bold', marginTop: 20 }}>
              3 BEDROOM DUPLEX
            </h4>
            <h5 className=''>351 SQM</h5>
            <NavLink
              className='btn btn-outline-light promo-action-button mt-5'
              to={'vr-tour/block-podium-unit-205-3-br-m-duplex'}
            >
              {'DISCOVER'}
            </NavLink>
          </div>
        </div>
        {/* <div className='promo-slide-down'>
          {t('VIEW_ALL_PROPERTIES')}
          <button
            type='button'
            className='promo-slide-down-button'
            onClick={handleClick}
          >
            <svg
              className='icon '
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              viewBox='0 0 24 24'
              width='20'
            >
              <path d='M0 0h24v24H0V0z' fill='none' />
              <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
            </svg>
          </button>
        </div>
        <div className='text-center'>
          <div className="container-fluid">
          <p>
            Donec a imperdiet odio, sit amet mollis erat. Quisque laoreet est id mi rhoncus, ac mattis massa feugiat. Integer et sem dolor. Donec tempus mauris lacus. Praesent nunc sapien, ullamcorper quis velit et, pretium faucibus arcu. Nulla facilisi. Nulla vel magna et nisl hendrerit dignissim. Pellentesque in enim ipsum. Vestibulum suscipit nunc arcu.
          </p>
          <p>
            Nam id sapien dolor. Etiam tristique lobortis purus sit amet pharetra. Aenean imperdiet lectus nisl, ac ullamcorper enim iaculis nec. Cras a ex ipsum. Duis semper vel turpis ut fermentum. Nullam efficitur felis eget mi scelerisque, non vulputate erat suscipit. Proin placerat lobortis leo sit amet porta. Pellentesque eleifend condimentum magna, at laoreet nisl placerat eget. Morbi vestibulum enim ullamcorper consectetur efficitur. Nulla facilisi.
          </p>
          </div>
          // <h6 className='promo-sub-title'> WELCOME TO</h6>
          <h2 className='promo-title'>
            AL FARIDAH BUILDING
          </h2>
          <hl style={{ width: 400 }} />
          <h4 className=''>AL RAHA BEACH</h4>

          <p className='promo-text'>
          {t('PROMO_1')}
          <br className='d-none d-lg-block' /> {t('PROMO_2')}
        </p>
          <NavLink
            className='btn btn-outline-light promo-action-button mt-5'
            to={'al-raha-beach'}
          >
            {'DISCOVER'}
          </NavLink> 
        </div>
        {/* <video
        data-keepplaying
        autoPlay
        muted
        loop
        playsInline
        preload='yes'
        className='promo-video'
        poster='/assets/img/promo/al-faridah-block-c2.jpg'
      >  <source
            src="//stream.ayanaholding.com/390973231220726219099013.mp4"
            type='video/mp4'
          /> 
      </video> */}
      </section>
    </InView>
  );
};

export default PromoVRTour;

import React, { Fragment, useEffect, useContext } from 'react';
import DataContext from '../context/dataContext';
import { InView } from 'react-intersection-observer';
import { fixedActiveNavigationPartDesktop } from '../animations/animation';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { Map, Slice } from 'react-lodash';
import _ from 'lodash';
import components from '../ComponentsConfig';

import { useParams } from 'react-router-dom';
import PromoTourview from '../components/PromoTourview';
import TourviewFixedNavigation from '../components/TourviewFixedNavigation';

gsap.registerPlugin(TextPlugin);

const Tourview = () => {
  const { t } = useTranslation();

  const fixedNavigaitonMobileHandler = (entry, name) => {
    if (entry === true) {
      gsap.to('.fixed-navigation-mobile-handler', {
        duration: 0.3,
        text: `${t($(name)[0].innerHTML)}`,
      });
    }

    // console.log($(name)[0].innerHTML);
  };
  return (
    <Fragment>
      <PromoTourview
        detailClass='detail-promo'
        // data={staticPage}
      />
      <main
        className='main-content'
        // style={{ backgroundColor: staticPage && staticPage.color }}
      >
        <TourviewFixedNavigation
          price={false}
          moneyType={false}
          navigationData={[{id: 1, name: 'OVERVIEW', to: 'overview'}, { id: 4, name: 'AMENITIES', to: 'amenities' }, { id: 5, name: 'GALLERY', to: 'gallery' }, {id: 2, name: 'INTERACTIVE MAP', to: 'interactive-map'}, {id: 3, name: 'LEASE NOW', to: 'lease-now'}]}
          backgroundColor={'#1d263d'}
        />
      </main>
    </Fragment>
  );
};

export default Tourview;

import React, { Fragment, useContext, useEffect } from 'react';
import DataContext from '../context/dataContext';
import { InView } from 'react-intersection-observer';
import { fixedActiveNavigationPartDesktop } from '../animations/animation';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Promo from '../components/Promo';
import FixedNavigation from '../components/FixedNavigation';
import RegisterForm from '../components/RegisterForm';
import Footer from '../components/Footer';

import DesktopGetInTouchBotton from '../components/DesktopGetInTouchBotton';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import { Map, Slice } from 'react-lodash';
import _ from 'lodash';
import components from '../ComponentsConfig';
// import ComponentContainer from '../ComponentContainer';

gsap.registerPlugin(TextPlugin);

const ProjectDetails = ({ location }) => {
  const dataContext = useContext(DataContext);
  const { getProject, project, money } = dataContext;
  const { t } = useTranslation();

  useEffect(() => {
    getProject(location);
    // eslint-disable-next-line
  }, []);


  let 
    sectionsIndex,
    slicer = [];
  if (location.state !== null) {
    // console.warn("project >> ", project);
    // project = projects.find((x) => x.slug === location.state);
    if (project) {
      const sections = _.filter(project.sections, {
        __component: 'components.section-name',
      });
      sectionsIndex = _.map(sections, (s) => {
        return _.findIndex(project.sections, s);
      });
      sectionsIndex.push(project.sections.length);
      // sectionsIndex.push(7);

      for (let index = 0; index <= sectionsIndex.length; index++) {
        slicer.push(_.take(sectionsIndex, 2));
        sectionsIndex = _.drop(sectionsIndex);
      }
    }
  }

  const fixedNavigaitonMobileHandler = (entry, name) => {
    if (entry === true) {
      gsap.to('.fixed-navigation-mobile-handler', {
        duration: 0.3,
        text: `${t($(name)[0].innerHTML)}`,
      });
    }

    // console.log($(name)[0].innerHTML);
  };
  return (
    <Fragment>
      <Promo
        detailClass='detail-promo'
        src={project && project.cover.url}
        sub_title={project && project.company.name}
        title={project && project.name}
        video={project && project.video && project.video.url}
        hasButton={false}
        buttonTitle={false}
        hasPrice={true}
        price={project && project.price}
      />
      <main
        className='main-content'
        style={{ backgroundColor: project && project.color }}
      >
        <FixedNavigation
          price={project && project.price}
          moneyType={money}
          navigationData={project}
          backgroundColor={project && project.color}
        />
        <FixedGetInTouchButton />
        <DesktopGetInTouchBotton />
        <Map
          key={Math.random()}
          collection={project && slicer}
          iteratee={(i) => (
            <Slice
              array={project.sections}
              start={i[0] + 1}
              end={i[1]}
              key={Math.random()}
            >
              {(value) => (
                <InView
                  onChange={(inView, entry) => {
                    fixedActiveNavigationPartDesktop(
                      'section-nav-' + project.sections[i[0]].id,
                      entry.isIntersecting
                    );
                    fixedNavigaitonMobileHandler(
                      entry.isIntersecting,
                      '#section-nav-' + project.sections[i[0]].id
                    );
                  }}
                >
                  <div
                    className='detail-section'
                    id={'section-' + project.sections[i[0]].id}
                    name={'section-' + project.sections[i[0]].id}
                  >
                    {value.map((comp, cIndex) => {
                      const Component = components.find(
                        (c) => c.name === comp.__component
                      ).component;
                      return (
                        <Component key={Math.random()} {...comp}>
                          {comp.__component}
                        </Component>
                      );
                    })}
                  </div>
                </InView>
              )}
            </Slice>
          )}
        />
      </main>
      <RegisterForm />
      <Footer />
    </Fragment>
  );
};

export default ProjectDetails;

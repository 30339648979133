import React, { useState } from "react";
import { Button, ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import { Range } from 'react-range';
import { clone } from 'lodash';
// import '../TourviewApp.css';
import { isMobile } from 'react-device-detect';

function Filter(props) {
    const [bedrooms, setBedrooms] = useState();
    const [floor, setFloor] = useState([0, 12]);
    const [sqm, setSqm] = useState([0, 500]);
    const [amenities, setAmenities] = useState('all');

    const [showFilters, setShowFilters] = useState(true);
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

    useState(() => {
        setTimeout(() => {
            setShowFilters(false);
        }, isMobile ? 0 : 3000);
    });

    function reset() {
        setBedrooms('all');
        setFloor([0, 12])
        setSqm([0, 500])
        setAmenities('all');
        props.setFilters({ bedrooms: 'all', floor: ['all'], sqm: ['all'] });
    }
    return (
        <div style={{
            width: isMobile && showFilters ? '100%' : isMobile && !showFilters ? '15%' : !isMobile && !showFilters ? '10%' : '100%',
            height: isMobile && showFilters ? isInstagram ? window.innerWidth : window.innerHeight : isMobile ? !isInstagram ? 70 : 100 : 120,
            backgroundColor: '#00000070',
            position: 'fixed',
            bottom: isMobile ? 0 : 65,
            transition: "all .1s ease-in",
            zIndex: 25,
        }} className={isInstagram ? 'filter-instagram' : ''}>
            <Row style={{
                paddingRight: 30,
                paddingLeft: 30
            }}>
                <Col md={isMobile ? 12 : showFilters ? 1 : 12} >
                    <div className="h-100 filter-container" onClick={() => {
                        setShowFilters(!showFilters);
                    }}>
                        <h5 style={{ textAlign: 'center' }}>Filters</h5>
                        <Row>
                            <Col md={12} style={{ textAlign: 'center' }}>
                                <img src="assets/img/rectangle.svg" style={{
                                    width: isMobile ? 18 : 25,
                                    marginTop: 7,
                                    transform: showFilters ? 'rotate(0)' : isMobile ? 'rotate(180deg)' : 'rotate(-90deg)'
                                }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                {showFilters && (
                    <>
                        <Col xl={2} md={isMobile ? 12 : 3}>
                            <div className="h-100 filter-container">
                                <h5>Bedrooms</h5>
                                <Row>
                                    <Col md={12}>
                                        <ButtonGroup>
                                            {[0, 1, 2, 3, 4].map((value, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    style={{
                                                        width: 40,
                                                        height: 40
                                                    }}
                                                    id={`bedroom-${idx}`}
                                                    type="radio"
                                                    className="bedroom-button"
                                                    name="bedroom"
                                                    value={value}
                                                    checked={bedrooms === value}
                                                    onChange={(e) => {
                                                        props.setIsFiltered(true);
                                                        console.warn("amenities >> ", amenities);
                                                        if (amenities !== 'all') {
                                                            delete props.filters.name;
                                                            setAmenities('all');
                                                        }
                                                        setBedrooms(value);
                                                        const x = clone(props.filters);
                                                        x['bedrooms'] = value;
                                                        console.warn("props.filters >> ", x);
                                                        props.setFilters(x);
                                                    }}
                                                >
                                                    {value === 0 ? 'st' : value}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={1} style={{ display: isMobile ? 'none' : 'block' }}></Col>
                        {!isInstagram ? (
                        <Col md={isMobile ? 6 : 2} xs={6} sm={6} style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <div className="h-100 filter-container">
                                <h5>Floor Range</h5>
                                <Row>
                                    <Col md={12}>
                                        <div style={{
                                            marginTop: 17
                                        }}>
                                            <Range
                                                step={1}
                                                min={0}
                                                max={12}
                                                values={floor}
                                                onChange={(values) => {
                                                    props.setIsFiltered(true);
                                                    if (amenities !== 'all') {
                                                        delete props.filters.name;
                                                        setAmenities('all');
                                                    }
                                                    setFloor(values)
                                                    const x = clone(props.filters);
                                                    x['floor'] = values;
                                                    console.warn("props.filters >> ", x);
                                                    props.setFilters(x);

                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '6px',
                                                            width: '100%',
                                                            backgroundColor: '#ccc'
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div
                                                        {...props}
                                                        className="slider-handles"
                                                    >{floor[props.key]}</div>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        ) : <></>}
                        {!isInstagram ? (
                        <Col md={isMobile ? 6 : 2} xs={6} sm={6} style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <div className="h-100 filter-container">
                                <h5>Area Range (sqm)</h5>
                                <Row>
                                    <Col md={12}>
                                        <div style={{
                                            marginTop: 17
                                        }}>
                                            <Range
                                                step={10}
                                                min={0}
                                                max={500}
                                                values={sqm}
                                                onChange={(values) => {
                                                    props.setIsFiltered(true);
                                                    if (amenities !== 'all') {
                                                        delete props.filters.name;
                                                        setAmenities('all');
                                                    }
                                                    setSqm(values);
                                                    const x = clone(props.filters);
                                                    x['sqm'] = values;
                                                    console.warn("props.filters >> ", x);
                                                    props.setFilters(x);
                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '6px',
                                                            width: '100%',
                                                            backgroundColor: '#ccc'
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div
                                                        {...props}
                                                        className="slider-handles"
                                                    >{sqm[props.key]}</div>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        ) : <></>}
                        <Col md={isMobile ? 12 : 3} xs={12} sm={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <div className="h-100 filter-container">
                                <h5>Facilities</h5>
                                <Row>
                                    <Col md={12}>
                                        <ButtonGroup>
                                            {['GYM', 'POOL', 'PATHWAY'].map((value, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`amenities-${idx}`}
                                                    type="radio"
                                                    className="facilities-button"
                                                    name="amenities"
                                                    value={value}
                                                    checked={amenities === value}
                                                    onChange={(e) => {
                                                        props.setIsFiltered(true);
                                                        reset();
                                                        setAmenities(value);
                                                        const x = clone(props.filters);
                                                        x['name'] = value;
                                                        x['bedrooms'] = 'all';
                                                        x['floor'] = 'all';
                                                        x['sqm'] = 'all';
                                                        console.warn("props.filters >> ", x);
                                                        props.setFilters(x);
                                                    }}
                                                >
                                                    {value === 0 ? 'st' : value}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Col>
                                    {/* <Col md={4}>
                                        <Button className="facilities-button" onClick={() => {
                                            setBedrooms('all');
                                            setFloor([0, 12])
                                            setSqm([0, 500])
                                            props.setFilters({ bedrooms: 'all', floor: ['all'], sqm: ['all'] });
                                        }}>Gym</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button className="facilities-button" onClick={() => {
                                            setBedrooms('all');
                                            setFloor([0, 12])
                                            setSqm([0, 500])
                                            props.setFilters({ bedrooms: 'all', floor: ['all'], sqm: ['all'] });
                                        }}>Pool</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button className="facilities-button" onClick={() => {
                                            setBedrooms('all');
                                            setFloor([0, 12])
                                            setSqm([0, 500])
                                            props.setFilters({ bedrooms: 'all', floor: ['all'], sqm: ['all'] });
                                        }}>Lounge</Button>
                                    </Col> */}
                                </Row>
                            </div>
                        </Col>
                        <Col md={isMobile ? 12 : 1}>
                            <div className="h-100 filter-container">
                                <Button className="clear-button" onClick={() => {
                                    props.setIsFiltered(true);
                                    reset();
                                }}>Clear</Button>
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    )
}

export default Filter;

import React from "react";

const FullImageCard = ({ image }) => {
  return (
    <div className='full-image-card pt-5'>
      <div className='container-fluid'>
        <img src={process.env.REACT_APP_API_URL + image.url} alt='' width="100%" />
      </div>
    </div>
  );
};

export default FullImageCard;

import React, { useReducer, useEffect } from 'react';
import qs from 'qs';
import axios from 'axios';
import DataContext from './dataContext';
import dataReducer from './dataReducer';
import {
  GET_PROJECTS,
  GET_LANG,
  GET_CURRENCY,
  GET_FILTEREDSTATE,
  GET_COMPANIES,
  GET_MONEYTYPE,
  GET_STATICPAGES,
  GET_PROJECT,
  SET_SIGNINDATA
} from './types';

const DataState = (props) => {
  const initialState = {
    staticPages: [],
    money: 'AED',
    companies: [],
    projects: [],
    project: null,
    filteredState: {},
    lang: null,
    currency: [],
    signinData: null,
  };
  const [state, dispatch] = useReducer(dataReducer, initialState);
  // Global Axios Header
  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      config.params = {
        'language.slug': localStorage.getItem('i18nextLng'),
      };
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getCurrency();
    getStaticPages();
    if(localStorage.getItem('signin-data')){
      setSigninData(JSON.parse(localStorage.getItem('signin-data')));
    }
    // eslint-disable-next-line
  }, [state.lang]);

  const getLang = (lang) => {
    try {
      dispatch({
        type: GET_LANG,
        payload: lang,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getMoneyType = (money) => {
    try {
      dispatch({
        type: GET_MONEYTYPE,
        payload: money,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const setSigninData = (signinData) => {
    console.warn("signinData >> setSigninData >> ", signinData);
    try {
      dispatch({
        type: SET_SIGNINDATA,
        payload: signinData,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const changeFilteredState = (filter) => {
    try {
      dispatch({
        type: GET_FILTEREDSTATE,
        payload: filter,
      });
    } catch (err) {
      console.log(err);
    }
  };
  // Get Currency
  const getCurrency = async () => {
    try {
      const res = await axios.get(
        'https://api.exchangeratesapi.io/latest?base=AED'
      );
      dispatch({
        type: GET_CURRENCY,
        payload: res.data.rates && res.data.rates.AED,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getCompanies = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies`);
      dispatch({
        type: GET_COMPANIES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getStaticPages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/static-pages`
      );
      dispatch({
        type: GET_STATICPAGES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getProject = async (location) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/projects?slug=${location.state}`
      );
      dispatch({
        type: GET_PROJECT,
        payload: res.data[0],
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getProjects = async (location, filteredState) => {
    if (location.state !== undefined) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects?company.slug=${location.state}`
        );
        dispatch({
          type: GET_PROJECTS,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      let {
        bedrooms,
        completionDate,
        lifeStyle,
        maxPrice,
        minPrice,
        propertyType,
      } = filteredState;

      if (minPrice === 'Min Price' || minPrice === undefined) {
        minPrice = 0;
      }
      if (maxPrice === 'Max Price' || maxPrice === undefined) {
        maxPrice = 100000000000000;
      }
      const prequery = {};
      if (lifeStyle) {
        prequery['life_styles.name'] = lifeStyle;
      }
      if (propertyType) {
        prequery['property_types.name'] = propertyType;
      }
      if (bedrooms) {
        prequery['bedrooms.name'] = bedrooms;
      }
      if (completionDate && completionDate !== 'Any') {
        prequery.completionDate = completionDate;
      }
      if (state.money === 'AED') {
        minPrice = minPrice * state.currency;
      }
      if (state.money === 'AED') {
        maxPrice = maxPrice * state.currency;
      }
      const query = qs.stringify(prequery, { arrayFormat: 'repeat' });

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects?${query}&price_gt=${minPrice}&price_lt=${maxPrice}`
        );
        dispatch({
          type: GET_PROJECTS,
          payload: res.data,
        });
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <DataContext.Provider
      value={{
        getLang,
        getCurrency,
        getProject,
        getProjects,
        getCompanies,
        projects: state.projects,
        project: state.project,
        companies: state.companies,
        lang: state.lang,
        getMoneyType,
        currency: state.currency,
        money: state.money,
        setSigninData,
        signinData: state.signinData,
        getStaticPages,
        staticPages: state.staticPages,
        changeFilteredState,
        filteredState: state.filteredState,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export default DataState;

import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import DataContext from '../context/dataContext';
const PromoProjects = ({
  video,
  src,
  detailClass,
  fullpageApi,
  title,
  company,
  slug,
  price
}) => {
  const handleClick = () => {
    fullpageApi
      ? fullpageApi.moveSectionDown()
      : scroller.scrollTo('myScrollToElement', {
          duration: 1000,
          delay: 50,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
  };
  const { t } = useTranslation();
  const dataContext = useContext(DataContext);
  const { money, currency } = dataContext;
  return (
    <section
      className={`promo ${detailClass ? detailClass : null}`}
      style={{
        backgroundImage: `url(${
          src ? process.env.REACT_APP_API_URL + src : ''
        })`,
      }}
    >
      <div className='promo-slide-down'>
        {price && (
          <div className='starting-price'>
            <h6> {t('RENTAL PRICE')}</h6>
            <div className='starting-price-info'>
              {money === 'USD'
                ? '$ ' + parseFloat(`${price}`).toLocaleString()
                : 'AED ' +
                  (parseFloat(price)).toLocaleString()}{' '}
              {}
            </div>
          </div>
        )}
        <button
          type='button'
          className='promo-slide-down-button'
          onClick={handleClick}
        >
          <svg
            className='icon '
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
          >
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
          </svg>
        </button>
      </div>
      <div className='promo-content'>
        <h6 className='promo-sub-title'>
          {company && company.name?.toUpperCase()}
        </h6>
        <h2 className='promo-title'>
          {title?.toUpperCase()}
        </h2>
        <NavLink
          className='btn btn-outline-light promo-action-button'
          state={slug}
          to={{ pathname: `/projects/${company.slug}/${slug}`, state: slug }}
        >
          {t('DISCOVER')}
        </NavLink>
      </div>
      {video && (
        <video
          data-keepplaying
          autoPlay
          muted
          loop
          playsInline
          preload='yes'
          className='promo-video'
          poster='/assets/img/promo/1.jpg'
        >
          <source
            src={process.env.REACT_APP_API_URL + video}
            type='video/mp4'
          />
        </video>
      )}
    </section>
  );
};

export default PromoProjects;

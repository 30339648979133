import React, { Fragment, useState, useContext, useEffect } from 'react';
import DataContext from '../context/dataContext';
import Footer from '../components/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import PromoLanding from '../components/PromoLanding';
import Promo from '../components/Promo';
import DesktopSearch from '../components/DesktopSearch';
import RegisterForm from '../components/RegisterForm';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import $ from 'jquery';
import CustomFixedNavigation from '../components/CustomFixedNavigation';
import PromoAmenities from '../components/PromoAmenities';
const Amenities = () => {
  const dataContext = useContext(DataContext);
  const { getCompanies, companies, lang } = dataContext;
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, [lang]);
  const [hide, setHide] = useState('');
  // Set key for React Fullpage
  let anchors = [];
  companies.map((company) => {
    anchors.push(company.slug);
    return anchors;
  });
  anchors.push('footerr');

  return (
    <Fragment>
      <div className='promo-slider'>
        {/* <div className='section'>
                  <PromoLanding fullpageApi={fullpageApi} />
                </div> */}

        {companies.map((company) => (
          <div className='section'>
            <PromoAmenities />
            <CustomFixedNavigation
              // price={project && project.price}
              // moneyType={money}
              navigationData={[{ id: 1, name: 'OVERVIEW', to: 'overview' }, { id: 4, name: 'AMENITIES', to: 'amenities', active: true }, { id: 5, name: 'GALLERY', to: 'gallery' }, { id: 2, name: 'INTERACTIVE MAP', to: 'interactive-map' }, { id: 3, name: 'LEASE NOW', to: 'lease-now' }]}
              backgroundColor={'#1d263d'}
            />
          </div>
        ))}
        <div>
          <div class="detail-section" style={{ backgroundColor: '#1d263d' }}>
            <div class="container-fluid pt-5">
              <div class="card card-row card-reverse">
                <img src="/assets/img/amenities/exteriors.jpg" alt="" style={{
                  objectFit: 'cover'
                }} />
                <div class="card-body">
                  <h4 class="card-title">EXTERIORS</h4>
                  <p class="card-text">
                    <ul>
                      <li>Footpaths and courtyards &ndash;paved in state, timber and stone like finishes</li>
                      <li>Patios and hardscape gardens with sweet soil.</li>
                      <li>Swimming pool.</li>
                      <li>Solid timber external doors.</li>
                      <li>Glass sliding doors to courtyards.</li>
                      <li>Double glazed windows</li>
                      <li>Outdoor dining area</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="card card-row ">
                <img src="/assets/img/amenities/interiors.jpg" alt="" style={{
                  objectFit: 'cover'
                }} />
                <div class="card-body">
                  <h4 class="card-title">INTERIORS</h4>
                  <p class="card-text">
                    <ul>
                      <li>Floors, walls and ceilings will be designed in harmony and consistent unity</li>
                      <li>Stone/ceramic homogeneous finish to all living and circulation areas.</li>
                      <li>Timber floor finish to all bedrooms.</li>
                      <li>Timber frame doors and panels with matching fittings.</li>
                      <li>Suspended gypsum ceiling to hallways, entrance, bathrooms and kitchen.</li>
                      <li>All living and bedrooms with high ceilings and paint finish.</li>
                      <li>Bedrooms with built-in wardrobe with shelves, hanger and rails.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="card card-row card-reverse">
                <img src="/assets/img/amenities/kitchen.jpg" alt="" style={{
                  objectFit: 'cover'
                }} />
                <div class="card-body">
                  <h4 class="card-title">KITCHEN & BEDROOMS</h4>
                  <p class="card-text">
                    <ul>
                      <li>Modern kitchens with cabinets with timber finish and matching fittings.</li>
                      <li>Stone countertops with polished finish.</li>
                      <li>White ceramic high quality sanitary wares. Accessories and fittings in stainless steel finish.</li>
                      <li>Built-in bathroom and vanity counter with cupboards below and splashboard in stone finish.</li>
                      <li>Large end-suites to master bedrooms with freestanding baths, separate shower and toilets.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="card card-row ">
              <img src="/assets/img/amenities/features.jpg" alt="" style={{
                  objectFit: 'cover'
                }} />
                <div class="card-body">
                  <h4 class="card-title">FEATURES</h4>
                  <p class="card-text">
                    <ul>
                      <li>Cable TV</li>
                      <li>Broad band telephone cabling.</li>
                      <li>Lightning protection.</li>
                      <li>Multi-split air conditioning systems throughout.</li>
                      <li>Maid room with end-suit/external access.</li>
                      <li>Large Cross-fit gym</li>
                      <li>Multipurpose hall with recreational center.</li>
                      <li>24-hour gated secure community.</li>
                      <li>CCTV at main community entrances.</li>
                      <li>Secure car garage with remote control access.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section' id='section-footer'>
          <RegisterForm />
          <Footer />
        </div>
      </div>
      <FixedGetInTouchButton />
      {/* <DesktopSearch hide={hide} /> */}
    </Fragment>
  );
};

export default Amenities;
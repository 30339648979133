import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import { isMobile } from 'react-device-detect';
import TourviewApp from '../tourview/TourviewApp';

const PromoVRTourDetails = ({ data }) => {
  let history = useHistory();

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const onWindowResize = () => {
    clearTimeout(window.resizeLag)
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }, 200)
  }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ), [])
  return (
    <InView
      onChange={(inView) => {
        handleScroll(inView);
      }}
    >
      <section className='promox'>
        <div className='promo-contentx tourview' style={{ backgroundColor: '#000', height: innerHeight }}>
          {/* <iframe src={'https://alfaridah.ae/xplor/'} width={innerWidth} height={innerHeight - (isMobile ? 0 : 65)} frameBorder="0" id='tourview'></iframe> */}
          <TourviewApp />
        </div>
      </section>
    </InView>
  );
};

export default PromoVRTourDetails;
